import React from "react";
import {
  Box,
  Flex,
  HStack,
  useColorModeValue,
  ChakraProvider,
  Image,
  Select,
} from "@chakra-ui/react";
import LogoMovil from "../images/logoSimple.png";
import LogoEscritorio from "../images/logoSiesa.png";
import { useLanguage } from "../componets/LanguageContext";

/* const content = {
  en: {},
  es: {},
}; */

const Navbar = () => {
  const { language, changeLanguage } = useLanguage();

  const onLanguageChange = (selectedLanguage) => {
    changeLanguage(selectedLanguage);
  };

  return (
    <ChakraProvider>
      <Box
        bg={useColorModeValue("gray.300", "gray.100")}
        px={4}
        style={{
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack spacing={8} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <Image
                src={LogoEscritorio}
                alt="Logo"
                width={{ base: 150, md: 120 }}
                display={{ base: "none", md: "block" }}
              />
            </Box>
            <Box display={{ base: "block", md: "none" }}>
              <Image
                src={LogoMovil}
                alt="Logo"
                width={{ base: 150, md: 120 }}
                display={{ base: "block", md: "none" }}
              />
            </Box>
          </HStack>
          <Flex alignItems={"center"} ml="auto">
            <Select
              value={language}
              onChange={(event) => onLanguageChange(event.target.value)}
              width="100px"
              mr={2}
              bg={"white"}
            >
              <option value="es">ES</option>
              <option value="en">EN</option>
            </Select>
          </Flex>
        </Flex>
      </Box>

      <Box bg="#f8b603" height="6px" width="100%" />
    </ChakraProvider>
  );
};

export default Navbar;
