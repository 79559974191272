import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog,
  Text,
  HStack,
  Select,
  Skeleton,
  Image,
  Modal,
  ModalOverlay,
  AspectRatio,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
} from "@chakra-ui/react";
import {
  BiPlus,
  BiTrash,
  BiPencil,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalAddFile from "../componets/ModalAddFile";
import ModalEditFile from "../componets/ModalEditFile";
import PdfViewer from "./PdfViewer";

const content = {
  en: {
    number: "#",
    name: "Name of file",
    discipline: "Discipline",
    addFile: "Add file",
    cancel: "Cancel",
    delete: "Delete",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete the file ",
    more: "More information",
    view: "Visualize",
    search: "Search file",
    action: "Action",
    type: "Type of file",
    selectFile: "Type of file",
    interrogacion: "?",
    nameFiles: "Name:",
    desFile: "Description:",
    typeFile: "File type:",
    close: "Close",
    detalle: "File details",
  },
  es: {
    number: "#",
    name: "Nombre del archivo",
    discipline: "Disciplina",
    addFile: "Agregar archivo",
    cancel: "Cancelar",
    delete: "Eliminar",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage: "¿Estás seguro de que quieres eliminar el archivo",
    more: "Más información",
    view: "Visualizar",
    search: "Buscar archivo",
    action: "Acción",
    type: "Tipo de archivo",
    selectFile: "Tipo de archivo",
    interrogacion: "?",
    nameFiles: "Nombre:",
    desFile: "Descripción:",
    typeFile: "Tipo del archivo:",
    close: "Cerrar",
    detalle: "Detalles del archivo",
  },
};

const FileDashboard = () => {
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const [files, setFiles] = useState([]);
  const [searchFile, setSearchFile] = useState("");
  const { language } = useLanguage();
  const [deleteFileName, setDeleteFileName] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const cancelRef = useRef();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFileId, setEditFileId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewedFile, setViewedFile] = useState(null);
  const [fileContent, setFileContent] = useState("");

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_FILE}/getAllFiles`)
      .then((response) => {
        setFiles(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error al obtener datos de archivos ", error);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const handleDeleteFile = (fileId, fileName) => {
    setDeleteFileId(fileId);
    setDeleteFileName(fileName);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteFileId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_FILE}/deleteFile/${deleteFileId}`)
      .then((response) => {
        console.log("Archivo eliminado:", response.data);
        setDeleteFileId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar archivo:", error);
      });
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const handleEditFile = (fileId) => {
    setEditFileId(fileId);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FILE}/getURLFile/${fileId}`
      );
      const fileData = response.data;
      setViewedFile(fileData);

      const fileContent = await fetchFileContent(fileData);
      if (fileContent) {
        setFileContent(fileContent);
      } else {
        setViewedFile(fileData);
        setFileContent("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo: ", error);
    }
  };

  const fetchFileContent = async (fileData) => {
    if (fileData.type === "text/plain") {
      const textResponse = await fetch(getUrl(fileData.url.trim()));
      const text = await textResponse.text();
      return text;
    }
    return null;
  };

  const getUrl = (file) => {
    return `https://api-siesa.in/archivos/${file}`;
  };

  const fileTypeLabels = {
    "application/msword": "WORD",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "WORD",
    "application/pdf": "PDF",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "POWERPOINT",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "EXCEL",
    "application/vnd.ms-excel": "EXCEL",
    "image/jpeg": "JPEG",
    "image/png": "PNG",
    "video/mp4": "MP4",
    "text/plain": "TXT",
    "image/webp": "WEBP",
    "application/octet-stream": "AutoCAD",
  };

  const getFileTypeLabel = (fileType) => {
    return fileTypeLabels[fileType] || fileType;
  };

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <Flex direction={{ base: "column", md: "row" }}>
              <Skeleton isLoaded={!loading}>
                <Flex direction={{ base: "column", md: "row" }} w="100%">
                  <InputGroup
                    maxW={{ base: "100%", md: "300px" }}
                    mr={{ base: 0, md: 2 }}
                  >
                    <Input
                      type="text"
                      name="name"
                      placeholder={content[language].search}
                      value={searchFile}
                      onChange={(e) => setSearchFile(e.target.value)}
                    />
                  </InputGroup>

                  <Select
                    placeholder={content[language].selectFile}
                    maxW={{ base: "100%", md: "250px" }}
                    mt={{ base: 1, md: 0 }}
                    mr={{ base: 0, md: 2 }}
                    value={selectedFileType}
                    onChange={(e) => setSelectedFileType(e.target.value)}
                  >
                    <option value="application/pdf">PDF</option>
                    <option value="application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                      WORD
                    </option>
                    <option value="application/vnd.openxmlformats-officedocument.presentationml.presentation">
                      POWER POINT
                    </option>
                    <option value="image/jpeg">JPG</option>
                    <option value="image/png">PNG</option>
                    <option value="video/mp4">MP4</option>
                    <option value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                      EXCEL
                    </option>
                    <option value="image/jpeg">JPEG</option>
                    <option value="text/plain">TXT</option>
                    <option value="image/webp">WEBP</option>
                    <option value="application/octet-stream">AUTOCAD</option>
                  </Select>
                </Flex>
              </Skeleton>
            </Flex>
          </FormControl>
          <Skeleton isLoaded={!loading}>
            <Button
              bg={"gray.300"}
              color="black"
              _hover={{
                bg: "gray.400",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              w={{ base: "auto", md: "auto" }}
              mt={{ base: 2, md: 0 }}
              onClick={handleIconClick}
            >
              {content[language].addFile}
            </Button>
          </Skeleton>
        </Flex>

        <Skeleton isLoaded={!loading}>
          <TableContainer
            style={{
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            }}
            pb={4}
          >
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>{content[language].number}</Th>
                  <Th>{content[language].name}</Th>
                  <Th>{content[language].discipline}</Th>
                  <Th>{content[language].type}</Th>
                  <Th>{content[language].action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {files
                  .filter((file) => {
                    const fileName = file.name.toLowerCase();
                    const fileType = file.type.toLowerCase();
                    const searchLower = searchFile.toLowerCase();
                    const selectedFileTypeLower =
                      selectedFileType.toLowerCase();
                    return (
                      fileName.includes(searchLower) &&
                      (selectedFileType === "" ||
                        fileType === selectedFileTypeLower)
                    );
                  })
                  .map((file, index) => (
                    <React.Fragment key={file.id}>
                      <Tr key={index}>
                        <Td>
                          <Box fontWeight="bold">{index + 1}</Box>
                        </Td>
                        <Td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {file.name}
                        </Td>
                        <Td
                          style={{
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {file.Discipline.name}
                        </Td>
                        <Td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {getFileTypeLabel(file.type)}
                        </Td>

                        <Td>
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteFile(file.id, file.name)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].edit}>
                              <Button
                                colorScheme="blue"
                                size="sm"
                                mr={2}
                                onClick={() => handleEditFile(file.id)}
                              >
                                <BiPencil />
                              </Button>
                            </Tooltip>
                          </>

                          <Tooltip label={content[language].more}>
                            <Button
                              onClick={() => toggleAccordion(file.id)}
                              colorScheme="gray"
                              size="sm"
                              mr={2}
                              border="1px solid black"
                            >
                              {activeAccordion === file.id ? (
                                <BiChevronUp />
                              ) : (
                                <BiChevronDown />
                              )}
                            </Button>
                          </Tooltip>
                        </Td>
                      </Tr>
                      {activeAccordion === file.id && (
                        <Tr>
                          <Td colSpan={2}>
                            <HStack>
                              <Button
                                size="sm"
                                colorScheme="gray"
                                leftIcon={<FaEye />}
                                mt={{ base: 2, md: 0 }}
                                onClick={() => handleViewFile(file.id)}
                                border="1px solid black"
                              >
                                {content[language].view}
                              </Button>
                              <Box w="10px" />
                              <Text
                                style={{
                                  maxWidth: "500px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {file.description}
                              </Text>
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                    </React.Fragment>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>
      </Box>

      {deleteFileId && (
        <AlertDialog
          isOpen={isDeleteConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmDeleteTitle}
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmDeleteMessage}{" "}
                <strong>{deleteFileName}</strong>
                {content[language].interrogacion}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelDelete}>
                  {content[language].cancel}
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  {content[language].delete}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {editFileId && (
        <ModalEditFile
          isOpen={isEditModalOpen}
          onClose={() => {
            setEditFileId(null);
            setIsEditModalOpen(false);
            fetchData();
          }}
          fileId={editFileId}
          fetchData={fetchData}
        />
      )}

      <ModalAddFile
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fetchData={fetchData}
      />

      {viewedFile && (
        <Modal isOpen={viewedFile !== null} onClose={() => setViewedFile(null)}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>{content[language].nameFiles}</strong> {viewedFile.name}
              </Text>
              <Text mb={5}>
                <strong>{content[language].desFile} </strong>{" "}
                {viewedFile.description}
              </Text>
              {/* /////////////////////////////// */}
              {viewedFile.type === "application/msword" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  ></iframe>
                </>
              )}
              {viewedFile.type === "application/vnd.ms-powerpoint" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.type === "application/vnd.ms-excel" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                <>
                  <Text>{getUrl(viewedFile.url.trim())}</Text>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameBorder="0"
                  />
                </>
              )}
              {/* /////////////////////////// */}
              {viewedFile.type === "image/jpeg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "image/x-icon" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "image/png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "video/mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={getUrl(viewedFile.url.trim())}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "text/plain" && (
                <Box>
                  <Text>{fileContent}</Text>
                </Box>
              )}
              {viewedFile.type === "image/webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "application/pdf" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
              {viewedFile.type === "application/octet-stream" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFile(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ChakraProvider>
  );
};

export default FileDashboard;
