import React from "react";
import NavbarPanel from "../../componets/NavbarPanel";
import DashboardClient from "../../componets/DashboardClient";
import { ChakraProvider } from "@chakra-ui/react";
import { LanguageProvider } from "../../componets/LanguageContext";

function Client() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <NavbarPanel />
        <DashboardClient />
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default Client;
