import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Skeleton,
} from "@chakra-ui/react";
import { BiPlus, BiTrash, BiPencil } from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalRegister from "../componets/ModalRegister";
import ModalEditUser from "../componets/ModalEditUser";

const content = {
  en: {
    number: "#",
    register: "Register user",
    search: "Search user",
    fullname: "Full Name",
    email: "Email",
    role: "Role",
    action: "Action",
    trash: "Delete",
    edit: "Edit",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete the user ",
    cancel: "Cancel",
    delete: "Delete",
    login: "Last login",
    dateInvalid: "Invalid date",
    password: "Password",
    interrogacion: "?",
  },
  es: {
    number: "#",
    register: "Registrar usuario",
    search: "Buscar usuario",
    fullname: "Nombre completo",
    email: "Correo electrónico",
    role: "Rol",
    action: "Acción",
    trash: "Eliminar",
    edit: "Editar",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage: "¿Estás seguro de que quieres eliminar al usuario",
    cancel: "Cancelar",
    delete: "Eliminar",
    login: "Último acceso",
    dateInvalid: "Fecha inválida",
    password: "Contraseña",
    interrogacion: "?",
  },
};

const UserDashboard = () => {
  const API_ENDPOINT_USER = "https://api-siesa.in/api/v1/users";

  const { language } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [deleteUserId, setDeleteUserId] = useState(null);
  const cancelRef = useRef();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [deleteUserName, setDeleteUserName] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleEditUser = (userId) => {
    setEditUserId(userId);
    setIsEditModalOpen(true);
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteUser = (userId, userName) => {
    setDeleteUserId(userId);
    setIsDeleteConfirmationModalOpen(true);
    setDeleteUserName(userName);
  };

  const handleCancelDelete = () => {
    setDeleteUserId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_USER}/delete/${deleteUserId}`)
      .then((response) => {
        setDeleteUserId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar usuario: ", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT_USER}/user-details`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de usuarios ", error);
      });
  }, []);

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_USER}/user-details`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de usuarios ", error);
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  function formatLastLogin(lastLogin) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (!lastLogin) {
      return content[language].dateInvalid;
    }

    const loginTime = new Date(lastLogin);
    const now = new Date();
    const diff = now - loginTime;
    const diffMinutes = Math.floor(diff / (1000 * 60));
    const diffHours = Math.floor(diff / (1000 * 60 * 60));

    if (diffHours < 1) {
      if (diffMinutes < 1) {
        return "hace unos segundos";
      } else {
        return `hace ${diffMinutes} minutos`;
      }
    } else if (diffHours < 24) {
      return `hace ${diffHours} horas`;
    } else if (diffHours < 48) {
      return "ayer";
    } else {
      return loginTime.toLocaleString("en-US", options);
    }
  }

  // cambia cada 60 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <Flex direction={{ base: "column", md: "row" }}>
              <Skeleton isLoaded={!loading}>
                <Flex direction={{ base: "column", md: "row" }} w="100%">
                  <InputGroup>
                    <Input
                      type="text"
                      name="name"
                      placeholder={content[language].search}
                      value={searchUser}
                      onChange={(e) => setSearchUser(e.target.value)}
                      maxW={{ base: "100%", md: "300px" }}
                    />
                  </InputGroup>
                </Flex>
              </Skeleton>
            </Flex>
          </FormControl>
          <Skeleton isLoaded={!loading}>
            <Button
              bg={"gray.300"}
              color="black"
              _hover={{
                bg: "gray.400",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              w={{ base: "95%", md: "auto" }}
              mt={{ base: 2, md: 0 }}
              onClick={handleIconClick}
            >
              {content[language].register}
            </Button>
          </Skeleton>
        </Flex>

        <Skeleton isLoaded={!loading}>
          <TableContainer
            style={{
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            }}
            pb={4}
          >
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>{content[language].number}</Th>
                  <Th>{content[language].fullname}</Th>
                  <Th>{content[language].email}</Th>

                  <Th>{content[language].role}</Th>
                  <Th>{content[language].login}</Th>
                  <Th>{content[language].action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users
                  .filter((user) => {
                    const email = user.email.toLowerCase();
                    const searchTerm = searchUser.toLowerCase();
                    return email.includes(searchTerm);
                  })
                  .map((user, index) => (
                    <React.Fragment key={user.id}>
                      <Tr key={index}>
                        <Td>
                          <Box fontWeight="bold">{index + 1}</Box>
                        </Td>
                        <Td>{user.name}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.Role.name}</Td>
                        <Td>{formatLastLogin(user.lastLogin)}</Td>
                        <Td>
                          {user.Role && user.Role.name !== "Administrator" && (
                            <>
                              <Tooltip label={content[language].trash}>
                                <Button
                                  colorScheme="red"
                                  size="sm"
                                  mr={2}
                                  onClick={() =>
                                    handleDeleteUser(user.id, user.name)
                                  }
                                >
                                  <BiTrash />
                                </Button>
                              </Tooltip>
                              <Tooltip label={content[language].edit}>
                                <Button
                                  colorScheme="blue"
                                  size="sm"
                                  mr={2}
                                  onClick={() => handleEditUser(user.id)}
                                >
                                  <BiPencil />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </Td>
                      </Tr>
                    </React.Fragment>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>

        {deleteUserId && (
          <AlertDialog
            isOpen={isDeleteConfirmationModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleCancelDelete}
          >
            <AlertDialogOverlay>
              <AlertDialogContent
                maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
              >
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  {content[language].confirmDeleteTitle}
                </AlertDialogHeader>

                <AlertDialogBody>
                  {content[language].confirmDeleteMessage}{" "}
                  <strong>{deleteUserName}</strong>
                  {content[language].interrogacion}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={handleCancelDelete}>
                    {content[language].cancel}
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={handleConfirmDelete}
                    ml={3}
                  >
                    {content[language].delete}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}

        {editUserId && (
          <ModalEditUser
            isOpen={isEditModalOpen}
            onClose={() => {
              setEditUserId(null);
              setIsEditModalOpen(false);
              fetchData();
            }}
            userId={editUserId}
          />
        )}

        <ModalRegister isOpen={isModalOpen} onClose={handleCloseModal} />
      </Box>
    </ChakraProvider>
  );
};

export default UserDashboard;
