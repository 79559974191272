import React from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Index from "./screens/Index";
import Admin from "./screens/admin/Admin";
import Client from "./screens/client/Client";
import File from "./screens/files/File";
import "./App.css";
import theme from "./theme";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";

function FileWithToken() {
  const location = useLocation();
  const token = location.pathname.split("/").pop();
  const hasToken = token && token !== "";
  return hasToken ? <File /> : <Navigate to="/" />;
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route
            path="/admin/home"
            element={<Admin token={localStorage.getItem("token")} />}
          />
          <Route
            path="/client/home"
            element={<Client token={localStorage.getItem("token")} />}
          />
          <Route path="/data/files/*" element={<FileWithToken />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
