import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleAddFolder: "Add Folder",
    name: "Name of folder:",
    description: "Description of folder:",
    uploadFiles: "Upload file(s):",
    cancel: "Cancel",
    save: "Save",
    titleAdd: "Folder added",
    errors: {
      validation: "Please select values for all options.",
      add: "Error adding the folder. Please try again.",
      nameRequired: "Please enter the name of the folder.",
      descriptionRequired: "Please enter the description of the folder.",
      fileRequired: "Please upload at least one file.",
    },
    success: {
      add: "The folder has been added successfully.",
    },
    remove: "Remove",
    folderDescription: "Description of the folder to load",
    folderName: "Name of the folder to load",
  },
  es: {
    titleAddFolder: "Agregar carpeta",
    name: "Nombre de la carpeta:",
    description: "Descripción de la carpeta:",
    uploadFiles: "Subir archivo(s):",
    cancel: "Cancelar",
    save: "Guardar",
    titleAdd: "Carpeta agregado",
    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      add: "Error al agregar la carpeta. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre de la carpeta.",
      descriptionRequired: "Por favor, ingrese la descripción de la carpeta.",
      fileRequired: "Por favor, cargue al menos un archivo.",
    },
    success: {
      add: "La carpeta se ha agregado correctamente.",
    },
    remove: "Eliminar",
    folderDescription: "Descripción de la carpeta a cargar",
    folderName: "Nombre de la carpeta a cargar",
  },
};

const ModalAddFolder = ({ isOpen, onClose, fetchData }) => {
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";

  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    files: [],
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        files: selectedFiles,
      }));
    }
  };

  const addFolder = async () => {
    try {
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.description) {
        toast({
          title: "Error",
          description: content[language].errors.descriptionRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (formData.files.length === 0) {
        toast({
          title: "Error",
          description: content[language].errors.fileRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);

      formData.files.forEach((file, index) => {
        formDataToSend.append("files", file);
      });

      const response = await axios.post(
        `${API_ENDPOINT_FOLDER}/addFolder`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: content[language].titleAdd,
          description: content[language].success.add,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          description: "",
          files: [],
        });

        onClose && onClose();
        fetchData && fetchData();
      } else {
        throw new Error("Error adding folder.");
      }
    } catch (error) {
      console.error("Error adding folder:", error);
      toast({
        title: "Error",
        description: content[language].errors.add,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData({
      name: "",
      description: "",
      files: [],
    });

    onClose && onClose();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleAddFolder}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={content[language].folderName}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="description">
                  {content[language].description}
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder={content[language].folderDescription}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="file">
                  {content[language].uploadFiles}
                </FormLabel>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  multiple
                  onChange={handleFileChange}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={handleClose}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={addFolder}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAddFolder;
