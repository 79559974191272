import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  Select,
  useToast,
  Alert,
  AlertIcon,
  ChakraProvider,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useLanguage } from "../componets/LanguageContext";
import axios from "axios";

const content = {
  en: {
    userRegistration: "User registration",
    firstName: "First Name:",
    lastName: "Last Name:",
    emailAddress: "Email Address:",
    enterEmail: "Enter email address",
    enterFirstName: "Enter First Name",
    enterLastName: "Enter Last Name",
    password: "Password:",
    enterPassword: "Enter Password",
    register: "Register",
    status: "Status:",
    role: "Role:",
    selectRole: "Select Role",
    selectStatus: "Select Status",
    errorData: "Error loading data",
    errorData2: "Error loading data. Please try again.",
    errorData3: "Error loading data:",
    accountCreatedTitle: "Account Created",
    accountCreatedDescription: "Your account has been successfully created!",
    genericError: "An error occurred. Please try again later.",
    dropdownsError: "Please select both status and role.",
    requiredFieldsError: "Please fill out all required fields correctly.",
    registering: "Registering...",
    emailAlreadyExists: "Email already registered.",
  },
  es: {
    userRegistration: "Registro de usuario",
    firstName: "Nombre:",
    lastName: "Apellido:",
    emailAddress: "Dirección de Correo Electrónico:",
    enterEmail: "Ingrese el correo electrónico",
    enterFirstName: "Ingrese el nombre",
    enterLastName: "Ingrese el apellido",
    password: "Contraseña:",
    enterPassword: "Ingrese la contraseña",
    register: "Registrar",
    status: "Estado:",
    role: "Rol:",
    selectRole: "Seleccionar Rol",
    selectStatus: "Seleccionar Estado",
    errorData: "Error al cargar datos",
    errorData2: "Error al cargar los datos. Inténtelo de nuevo.",
    errorData3: "Error durante la carga de datos:",
    accountCreatedTitle: "Cuenta Creada",
    accountCreatedDescription: "¡Su cuenta ha sido creada exitosamente!",
    genericError:
      "Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.",
    dropdownsError: "Por favor, seleccione tanto el estado como el rol.",
    requiredFieldsError:
      "Por favor, complete todos los campos requeridos correctamente.",
    registering: "Registrando...",
    emailAlreadyExists: "El correo electrónico ya está registrado.",
  },
};

export default function Register({ onClose }) {
  const API_ENDPOINT_ROLE = "https://api-siesa.in/api/v1/roles";
  const API_ENDPOINT_STATUS = "https://api-siesa.in/api/v1/statuses";
  const API_ENDPOINT_USER = "https://api-siesa.in/api/v1/users";
  const { language } = useLanguage();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    roles: [],
    statuses: [],
    roleId: "",
    statusId: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    // other error properties...
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const toast = useToast();

  const loadData = async () => {
    try {
      const rolesResponse = await axios.get(`${API_ENDPOINT_ROLE}/getAllRoles`);
      const statusesResponse = await axios.get(
        `${API_ENDPOINT_STATUS}/getAllStatuses`
      );

      if (rolesResponse.status === 200 && statusesResponse.status === 200) {
        const roles = rolesResponse.data;
        const statuses = statusesResponse.data;

        setUserData((prevData) => ({
          ...prevData,
          roles,
          statuses,
          roleId: prevData.roleId,
          statusId: prevData.statusId,
        }));
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError();
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestError = () => {
    toast({
      title: "Error",
      description: content[language].errorData2,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Limpiar errores cuando se modifica un campo
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Limpiar el error específico para este campo
    }));
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      password: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: content[language].emailFormatError, // Update this line
      }));
    } else {
      // Clear the email error if validation is successful
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "", // Clear the email error
      }));
    }

    return isValid;
  };

  const handleEmailBlur = () => {
    const { email } = userData;

    if (!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: content[language].requiredFieldsError,
      }));
      return;
    }

    if (!validateEmail(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: content[language].emailFormatError,
      }));
      return;
    }

    validateEmailExistence(email);
  };

  const validateEmailExistence = async (email) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_USER}/user-emails-details`,
        {
          params: { email },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Error",
          description: content[language].emailAlreadyExists,
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } catch (error) {
      /* setErrors(content[language].emailFormatError); */
      setButtonDisabled(false);
    }
  };

  const handleSubmit = async () => {
    const { firstname, lastname, email, password, statusId, roleId } = userData;
    let formIsValid = true;
    const newErrors = {};

    if (!firstname) {
      newErrors.firstname = content[language].requiredFieldsError;
      formIsValid = false;
    }

    if (!lastname) {
      newErrors.lastname = content[language].requiredFieldsError;
      formIsValid = false;
    }

    if (!email) {
      newErrors.email = content[language].requiredFieldsError;
      formIsValid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = content[language].emailFormatError;
      formIsValid = false;
    }

    if (!password) {
      newErrors.password = content[language].requiredFieldsError;
      formIsValid = false;
    }

    if (!statusId || !roleId) {
      toast({
        title: "Error",
        description: content[language].dropdownsError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      formIsValid = false;
    }

    if (formIsValid) {
      try {
        setLoading(true);
        const registerResponse = await axios.post(
          `${API_ENDPOINT_USER}/register`,
          {
            firstname,
            lastname,
            email,
            password,
            StatusId: statusId,
            RoleId: roleId,
          }
        );

        if (registerResponse.status === 201) {
          toast({
            title: content[language].accountCreatedTitle,
            description: content[language].accountCreatedDescription,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          onClose && onClose();
        } else {
          toast({
            title: "Error",
            description: content[language].genericError,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error al crear la cuenta:", error);
        toast({
          title: "Error",
          description: content[language].genericError,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      // Actualizar los errores del formulario
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
    }
  };

  return (
    <ChakraProvider maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
      <Flex
        /* minH={"45vh"} */
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>
              {content[language].userRegistration}
            </Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>{content[language].firstName}</FormLabel>
                    <Input
                      type="text"
                      name="firstname"
                      value={userData.firstname}
                      onChange={handleChange}
                      placeholder={content[language].enterFirstName}
                    />
                    {errors.firstname && (
                      <Alert status="error" mt={2}>
                        <AlertIcon />
                        {errors.firstname}
                      </Alert>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>{content[language].lastName}</FormLabel>
                    <Input
                      type="text"
                      name="lastname"
                      value={userData.lastname}
                      onChange={handleChange}
                      placeholder={content[language].enterLastName}
                    />
                    {errors.lastname && (
                      <Alert status="error" mt={2}>
                        <AlertIcon />
                        {errors.lastname}
                      </Alert>
                    )}
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>{content[language].emailAddress}</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                  placeholder={content[language].enterEmail}
                />
                {errors.email && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {errors.email}
                  </Alert>
                )}
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>{content[language].password}</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={userData.password}
                    onChange={handlePasswordChange}
                    placeholder={content[language].enterPassword}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() => setShowPassword((show) => !show)}
                    >
                      {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {errors.password}
                  </Alert>
                )}
              </FormControl>
              <FormControl id="role" isRequired>
                <FormLabel>{content[language].role}</FormLabel>
                <Select
                  placeholder={content[language].selectRole}
                  name="roleId"
                  value={userData.roleId}
                  onChange={handleChange}
                >
                  {userData.roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="status" isRequired>
                <FormLabel>{content[language].status}</FormLabel>
                <Select
                  placeholder={content[language].selectStatus}
                  name="statusId"
                  value={userData.statusId}
                  onChange={handleChange}
                >
                  {userData.statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText={content[language].registering}
                  size="lg"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={handleSubmit}
                  isDisabled={buttonDisabled || loading}
                  isLoading={loading}
                >
                  {content[language].register}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </ChakraProvider>
  );
}
