import React, { useState } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ChakraProvider,
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import UserDashboard from "./UserDashboard";
import FileDashboard from "./FileDashboard";
import FolderDashboard from "./FolderDashboard";
import InformationDashboard from "./InformationDashboard";

export default function DashboardAdmin() {
  const { language } = useLanguage();
  const [key, setKey] = useState(0);

  const content = {
    en: {
      file: "File",
      user: "User",
      general: "General",
      folder: "Folder"
    },
    es: {
      file: "Archivo",
      user: "Usuario",
      general: "General",
      folder: "Carpeta"
    },
  };

  return (
    <ChakraProvider>
      <Tabs
        isFitted
        variant="enclosed"
        colorScheme="black"
        pt={3}
        onChange={(index) => setKey(index)}
      >
        <TabList mb="1em">
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].general}
          </Tab>
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].user}
          </Tab>
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].file}
          </Tab>
          <Tab _selected={{ borderBottom: "3px solid black" }}>
            {content[language].folder}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel key={key}>
            <InformationDashboard />
          </TabPanel>
          <TabPanel key={key}>
            <UserDashboard />
          </TabPanel>
          <TabPanel key={key}>
            <FileDashboard />
          </TabPanel>
          <TabPanel key={key}>
            <FolderDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}
