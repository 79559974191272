import React from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Navbar from "../componets/Navbar";
import Login from "../componets/Login";
import Footer from "../componets/Footer";
import theme from "../theme";
import { LanguageProvider } from "../componets/LanguageContext";

function Index() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <LanguageProvider>
        <Navbar />
        <Login />
        <Footer />
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default Index;
