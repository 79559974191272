import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Divider,
  Skeleton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
} from "@chakra-ui/react";
import { FiMail, FiFile, FiUser, FiFolder } from "react-icons/fi";
import { useLanguage } from "./LanguageContext";
import TableUser from "./TableUser";
import axios from "axios";

function StatsCard({ title, stat, icon }) {
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function Dashboard() {
  const API_ENDPOINT_USER = "https://api-siesa.in/api/v1/users";
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_EMAIL = "https://api-siesa.in/api/v1/emails";
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";
  const { language } = useLanguage();
  const [fileCount, setFileCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [emailsCount, setEmailsCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [emailLinkCount, setEmailLinkCount] = useState(0);
  const [folderCount, setFolderCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileResponse = await axios.get(
          `${API_ENDPOINT_FILE}/getAllFiles`
        );
        setFileCount(fileResponse.data.length);

        const userResponse = await axios.get(
          `${API_ENDPOINT_USER}/user-details`
        );
        setUserCount(userResponse.data.length);

        const emailsResponse = await axios.get(
          `${API_ENDPOINT_EMAIL}/getAllEmailss`
        );
        setEmailsCount(emailsResponse.data.length);

        const emailResponse = await axios.get(
          `${API_ENDPOINT_EMAIL}/getAllEmails`
        );
        setEmailCount(emailResponse.data.length);

        const emailLinkResponse = await axios.get(
          `${API_ENDPOINT_EMAIL}/getAllEmailLink`
        );
        setEmailLinkCount(emailLinkResponse.data.length);

        const folderResponse = await axios.get(
          `${API_ENDPOINT_FOLDER}/getAllFolders`
        );
        setFolderCount(folderResponse.data.length);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchData();
  }, []);

  const content = {
    en: {
      user: "Users",
      file: "Files",
      folder: "Folders",
      email: "Emails",
      adjuntado: "Emails sent with files attached:",
      link: "Emails sent with link:",
      info: "Information",
    },
    es: {
      user: "Usuarios",
      file: "Archivos",
      folder: "Carpetas",
      email: "Correos",
      adjuntado: "Correos enviados con archivos adjuntados: ",
      link: "Correos enviados con enlace: ",
      info: "Información",
    },
  };

  return (
    <Box
      style={{
        maxHeight: "100%",
        overflowY: "scroll",
        padding: "1rem",
      }}
    >
      <Box maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
          <Skeleton isLoaded={!loading}>
            <StatsCard
              title={content[language].user}
              stat={userCount}
              icon={<FiUser size={"4em"} />}
            />
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <StatsCard
              title={content[language].file}
              stat={fileCount}
              icon={<FiFile size={"4em"} />}
            />
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <StatsCard
              title={content[language].folder}
              stat={folderCount}
              icon={<FiFolder size={"4em"} />}
            />
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <StatsCard
              title={content[language].email}
              stat={
                <>
                  <Popover>
                    <PopoverTrigger>
                      <Button>{emailsCount}</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>{content[language].info}</PopoverHeader>
                      <PopoverBody>
                        {" "}
                        <Box
                          display="flex"
                          flexDirection="column"
                          fontSize="0.5em"
                        >
                          <Box>
                            {content[language].adjuntado} {emailCount}
                          </Box>
                          <Box>
                            {content[language].link} {emailLinkCount}
                          </Box>
                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
              }
              icon={<FiMail size={"4em"} />}
            />
          </Skeleton>
        </SimpleGrid>

        <Divider my={5} orientation="horizontal" />

        <Box mt={5}>
          <TableUser />
        </Box>
      </Box>
    </Box>
  );
}
