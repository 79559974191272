import React from "react";
import NavbarPanel from "../../componets/NavbarPanel";
import DashboardAdmin from "../../componets/DashboardAdmin";
import { ChakraProvider } from "@chakra-ui/react";
import { LanguageProvider } from "../../componets/LanguageContext";

function Admin() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <NavbarPanel />
        <DashboardAdmin />
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default Admin;
