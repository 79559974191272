import React from "react";
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  ChakraProvider,
  Tooltip,
} from "@chakra-ui/react";
import { FaInstagram, FaPhone, FaHome, FaWhatsapp } from "react-icons/fa";
import { useLanguage } from "../componets/LanguageContext";

function SocialButton({ children, label, href }) {
  return (
    <Tooltip label={label} aria-label={label}>
      <chakra.button
        bg={useColorModeValue("gray.50", "gray.100")}
        rounded={"full"}
        w={8}
        h={8}
        cursor={"pointer"}
        as={"a"}
        href={href}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        transition={"background 0.3s ease"}
        _hover={{
          bg: useColorModeValue("blue.100", "blue.200"),
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    </Tooltip>
  );
}

export default function Footer() {
  const { language } = useLanguage();

  const content = {
    en: {
      rightsReserved: "All rights reserved.",
      whatsapp: "Send us a Whatsapp",
      call: "Call us",
      visit: "Visit our website",
    },
    es: {
      rightsReserved: "Todos los derechos reservados.",
      whatsapp: "Envíanos un Whatsapp",
      call: "Llámanos",
      visit: "Visite nuestro sitio web",
    },
  };

  return (
    <ChakraProvider>
      <Box
        bg={useColorModeValue("gray.300", "gray.100")}
        color={useColorModeValue("gray.700", "gray.200")}
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
      >
        <Box bg="blue.400" height="6px" width="100%" />
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>
            &copy; {new Date().getFullYear()} SIESA -{" "}
            {content[language].rightsReserved}
          </Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Instagram"}
              href={"https://www.instagram.com/siesa_pty/"}
              target="_blank"
            >
              <FaInstagram />
            </SocialButton>
            <SocialButton
              label={content[language].call}
              href={"tel:+50768226048"}
            >
              <FaPhone />
            </SocialButton>

            <SocialButton
              label={content[language].whatsapp}
              href={`https://wa.me/${+50768226048}`}
            >
              <FaWhatsapp />
            </SocialButton>
            <SocialButton
              label={content[language].visit}
              href={"https://siesa.com.pa"}
              target="_blank"
            >
              <FaHome />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </ChakraProvider>
  );
}
