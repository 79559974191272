import React, { useState, useEffect, createContext, useContext } from "react";
import {
  ChakraProvider,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  Select,
  Skeleton,
  Image,
  Modal,
  ModalOverlay,
  AspectRatio,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Text,
  Stack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaEye, FaCloudDownloadAlt } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import PdfViewer from "../../componets/PdfViewer";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("es");

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  return useContext(LanguageContext);
};

const content = {
  en: {
    id: "Id",
    name: "Name of file",
    description: "Description",
    action: "Action",
    search: "Search user",
    view: "See file",
    close: "Close",
    detalle: "File details",
    noFiles:
      "There are no files available, please contact the administrator or reply to the email 'hduranc24@gmail.com'.",
    nameFiles: "Name:",
    desFile: "Description:",
    donwload: "Download",
    timeLeft: "Time left:",
    days: "day(s)",
    hours: "hour(s)",
    minutes: "minute(s)",
    seconds: "second(s)",
  },

  es: {
    id: "Id",
    name: "Nombre del archivo",
    description: "Descripción",
    action: "Acción",
    search: "Buscar usuario",
    view: "Ver archivo",
    close: "Cerrar",
    detalle: "Detalles del archivo",
    noFiles:
      "No hay archivos disponibles, por favor contacte al administrador o responda al correo electrónico 'hduranc24@gmail.com'.",
    nameFiles: "Nombre:",
    desFile: "Descripción:",
    donwload: "Descargar",
    timeLeft: "Tiempo restante:",
    days: "día(s)",
    hours: "hora(s)",
    minutes: "minuto(s)",
    seconds: "segundo(s)",
  },
};

const File = () => {
  const API_ENDPOINT_USERSFILES = "https://api-siesa.in/api/v1/usersfiles";
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";

  const { language, changeLanguage } = useLanguage() || {
    language: "en",
    changeLanguage: () => {},
  };

  const onLanguageChange = (selectedLanguage) => {
    changeLanguage(selectedLanguage);
  };

  const [viewedFile, setViewedFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [activeAccordionFile, setActiveAccordionFile] = useState(null);
  const [expandedFilesFolder, setExpandedFilesFolder] = useState([]);
  const [fileContentFolder, setFileContentFolder] = useState("");
  const [viewedFileFolder, setViewedFileFolder] = useState(null);

  const calculateTimeLeft = (expiry) => {
    const currentTime = Date.now();

    // Convertir a fecha si expiry no es una fecha válida
    if (!(expiry instanceof Date)) {
      expiry = new Date(expiry);
      console.log(expiry);
    }

    const difference = expiry - currentTime;

    // Calcula los días, horas, minutos y segundos restantes
    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return timeLeft;
  };

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const pathname = window.location.pathname;
        const token = pathname.split("/").pop();
        if (!token) {
          console.error("No se encontró ningún token en la URL");
          return;
        }
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        const userReason = decodedToken.reason;
        const timeExpire = decodedToken.expiry;

        const response = await axios.get(
          `${API_ENDPOINT_USERSFILES}/getUserFiles/${userId}/${userReason}`
        );

        const data = response.data || { files: [], folders: [] };

        const filteredFiles = (data.files || []).filter(
          (file) => file !== null
        );
        const filteredFolders = (data.folders || []).filter(
          (folder) => folder !== null
        );

        setUserFiles({ files: filteredFiles, folders: filteredFolders });
        setLoading(false);

        const initialTimeLeft = calculateTimeLeft(timeExpire);
        setTimeLeft(initialTimeLeft);

        const interval = setInterval(() => {
          const currentTimeLeft = calculateTimeLeft(timeExpire);
          setTimeLeft(currentTimeLeft);
        }, 1000);

        return () => clearInterval(interval);
      } catch (error) {
        console.error("Error al recuperar archivos de usuario: ", error);
        setUserFiles([]);
      }
    };

    fetchUserFiles();
  }, []);

  const handleViewFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FILE}/getURLFile/${fileId}`
      );
      const fileData = response.data;
      setViewedFile(fileData);

      const fileContent = await fetchFileContent(fileData);
      if (fileContent) {
        setFileContent(fileContent);
      } else {
        setViewedFile(fileData);
        setFileContent("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo: ", error);
    }
  };

  const fetchFileContent = async (fileData) => {
    if (fileData.type === "text/plain") {
      const textResponse = await fetch(getUrl(fileData.url.trim()));
      const text = await textResponse.text();
      return text;
    }
    return null;
  };

  const handleDownload = async (fileId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FILE}/getURLFile/${fileId}`
      );
      const fileData = response.data;
      const url = getUrl(fileData.url);
      const responseBlob = await fetch(url);
      const blob = await responseBlob.blob();
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileData.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error al descargar el archivo: `, error);
    }
  };

  const handleDownloadFolder = async (fileName) => {
    try {
      const fullURL = `https://api-siesa.in/archivos/${fileName}`;

      // 2. Realizar una solicitud para obtener el archivo
      const responseBlob = await fetch(fullURL);
      const blob = await responseBlob.blob();

      // 3. Crear el enlace de descarga
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // 4. Establecer el nombre de descarga
      link.setAttribute("download", fileName);

      // 5. Simular clic en el enlace para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      // 6. Limpiar después de la descarga
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error al descargar el archivo: `, error);
    }
  };

  const getUrl = (file) => {
    return `https://api-siesa.in/archivos/${file}`;
  };

  const toggleAccordionFile = async (folderId) => {
    const isActive = activeAccordionFile === `${folderId}`;

    if (isActive) {
      setActiveAccordionFile(null);
      setExpandedFilesFolder([]);
    } else {
      setActiveAccordionFile(`${folderId}`);

      try {
        const response = await axios.get(
          `${API_ENDPOINT_FOLDER}/getFilesByFolder/${folderId}`
        );

        if (response.data.length === 0) {
          console.log(response.data);
          setExpandedFilesFolder([{ message: "No hay archivos" }]);
        } else {
          setExpandedFilesFolder(response.data);
        }
      } catch (error) {
        console.error("Error al recuperar archivos de la carpeta: ", error);
      }
    }
  };

  const handleViewFileFolder = async (fileName) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FOLDER}/getURLFile/${fileName}`
      );
      const fileDetails = response.data;
      setViewedFileFolder(fileDetails);

      if (fileDetails.extension === "txt") {
        const content = await fetchFileContentFolder(fileDetails.url);
        setFileContentFolder(content);
      } else {
        setFileContentFolder("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo:", error);
    }
  };

  const fetchFileContentFolder = async (fileUrl) => {
    try {
      const textResponse = await fetch(fileUrl);
      const text = await textResponse.text();
      return text;
    } catch (error) {
      console.error("Error fetching text file content:", error);
      return "";
    }
  };

  const shouldShowTableBody =
    timeLeft &&
    timeLeft.days > 0 &&
    timeLeft.hours > 0 &&
    timeLeft.minutes > 0 &&
    timeLeft.seconds > 0;

  return (
    <ChakraProvider>
      <Box style={{ maxHeight: "100%", overflowY: "scroll", padding: "1rem" }}>
        <Skeleton isLoaded={!loading}>
          <div style={{ marginBottom: "1rem" }}>
            {" "}
            <Select
              value={language}
              onChange={(event) => onLanguageChange(event.target.value)}
              width="100px"
              mr={2}
              bg={"white"}
            >
              <option value="es">ES</option>
              <option value="en">EN</option>
            </Select>
          </div>
        </Skeleton>

        {timeLeft && (
          <Skeleton isLoaded={!loading}>
            <Box
              bg="gray.100"
              p={2}
              borderRadius="md"
              mt={2}
              display="inline-block"
              mb={"1rem"}
            >
              <Text fontSize="sm">
                {content[language].timeLeft} {timeLeft.days}{" "}
                {content[language].days} {timeLeft.hours}{" "}
                {content[language].hours} {timeLeft.minutes}{" "}
                {content[language].minutes} {timeLeft.seconds}{" "}
                {content[language].seconds}
              </Text>
            </Box>
          </Skeleton>
        )}

        <Skeleton isLoaded={!loading}>
          <TableContainer
            style={{
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            }}
            pb={4}
          >
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>{content[language].id}</Th>
                  <Th>{content[language].name}</Th>
                  <Th>{content[language].description}</Th>
                  <Th>{content[language].action}</Th>
                </Tr>
              </Thead>
              {shouldShowTableBody && (
                <Tbody>
                  {Array.isArray(userFiles.files) && userFiles.files.length > 0
                    ? userFiles.files.map((file) => (
                        <Tr key={file.id}>
                          <Td>{file.id}</Td>
                          <Td>{file.name}</Td>
                          <Td
                            style={{
                              maxWidth: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {file.description}
                          </Td>
                          <Td>
                            <Tooltip label={content[language].view}>
                              <Button
                                leftIcon={<FaEye />}
                                colorScheme="gray"
                                size="sm"
                                mr={2}
                                border="1px solid black"
                                onClick={() => handleViewFile(file.id)}
                              >
                                {content[language].view}
                              </Button>
                            </Tooltip>
                            <Tooltip label={content[language].donwload}>
                              <Button
                                leftIcon={<FaCloudDownloadAlt />}
                                colorScheme="gray"
                                size="sm"
                                mr={2}
                                border="1px solid black"
                                onClick={() => handleDownload(file.id)}
                              >
                                {content[language].donwload}
                              </Button>
                            </Tooltip>
                          </Td>
                        </Tr>
                      ))
                    : null}

                  {Array.isArray(userFiles.folders) &&
                  userFiles.folders.length > 0
                    ? userFiles.folders.map((folder) => (
                        <React.Fragment key={folder.id}>
                          <Tr key={folder.id}>
                            <Td>{folder.id}</Td>
                            <Td>{folder.name}</Td>
                            <Td
                              style={{
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {folder.description}
                            </Td>
                            <Td>
                              <Tooltip label={content[language].view}>
                                <Button
                                  colorScheme="gray"
                                  size="sm"
                                  mr={2}
                                  onClick={() => toggleAccordionFile(folder.id)}
                                  border="1px solid black"
                                >
                                  {activeAccordionFile === `${folder.id}` ? (
                                    <BiChevronUp />
                                  ) : (
                                    <BiChevronDown />
                                  )}
                                </Button>
                              </Tooltip>
                            </Td>
                          </Tr>
                          {activeAccordionFile === `${folder.id}` &&
                            expandedFilesFolder.files &&
                            expandedFilesFolder.files.map((file, index) => (
                              <React.Fragment key={file.id}>
                                <Tr>
                                  <Td colSpan={3}>
                                    <HStack>
                                      <Button
                                        size="sm"
                                        colorScheme="gray"
                                        leftIcon={<FaEye />}
                                        mt={{ base: 2, md: 0 }}
                                        border="1px solid black"
                                        onClick={() =>
                                          handleViewFileFolder(file.name)
                                        }
                                      >
                                        {content[language].view}
                                      </Button>
                                      <Box w="5px" />
                                      <Button
                                        size="sm"
                                        colorScheme="gray"
                                        leftIcon={<FaCloudDownloadAlt />}
                                        mt={{ base: 2, md: 0 }}
                                        border="1px solid black"
                                        onClick={() =>
                                          handleDownloadFolder(file.name)
                                        }
                                      >
                                        {content[language].donwload}
                                      </Button>
                                      <Box w="10px" />
                                      <Stack spacing={1}>
                                        <Text
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {file.name}
                                        </Text>
                                      </Stack>
                                    </HStack>
                                  </Td>
                                </Tr>
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      ))
                    : null}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Skeleton>
      </Box>

      {viewedFile && (
        <Modal isOpen={viewedFile !== null} onClose={() => setViewedFile(null)}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>{content[language].nameFiles}</strong> {viewedFile.name}
              </Text>
              <Text mb={5}>
                <strong>{content[language].desFile} </strong>{" "}
                {viewedFile.description}
              </Text>
              {/* /////////////////////////////// */}
              {viewedFile.type === "application/msword" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  ></iframe>
                </>
              )}
              {viewedFile.type === "application/vnd.ms-powerpoint" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type === "application/vnd.ms-excel" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                <>
                  <Text>{getUrl(viewedFile.url.trim())}</Text>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {/* /////////////////////////// */}
              {viewedFile.type === "image/jpeg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "image/png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "video/mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={getUrl(viewedFile.url.trim())}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "text/plain" && (
                <Box>
                  <Text>{fileContent}</Text>
                </Box>
              )}
              {viewedFile.type === "image/webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "application/pdf" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
              {viewedFile.type === "application/octet-stream" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFile(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {viewedFileFolder && (
        <Modal
          isOpen={viewedFileFolder !== null}
          onClose={() => setViewedFileFolder(null)}
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={5}>
                <strong>{content[language].nameFiles}</strong>{" "}
                {viewedFileFolder.name}
              </Text>

              {viewedFileFolder.extension === "pdf" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
              {viewedFileFolder.extension === "docx" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  ></iframe>
                </>
              )}
              {viewedFileFolder.extension === "pptx" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "xlsx" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "jpg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "ico" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={viewedFileFolder.url.trim()}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "txt" && (
                <Box>
                  <Text>{fileContentFolder}</Text>
                </Box>
              )}
              {viewedFileFolder.extension === "dwg" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFileFolder(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ChakraProvider>
  );
};

const AppFile = () => (
  <LanguageProvider>
    <File />
  </LanguageProvider>
);

export default AppFile;
