import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
  FormControl,
  InputGroup,
  Input,
  Button,
  Flex,
  Tooltip,
  Box,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog,
  Text,
  HStack,
  Skeleton,
  Image,
  Modal,
  ModalOverlay,
  AspectRatio,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
} from "@chakra-ui/react";
import { BiPlus, BiTrash, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import ModalAddFolder from "../componets/ModalAddFolder";
import PdfViewer from "./PdfViewer";

const content = {
  en: {
    number: "#",
    name: "Name of folder",
    addFolder: "Add folder",
    cancel: "Cancel",
    delete: "Delete",
    description: "Description",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage: "Are you sure you want to delete the folder ",
    more: "More information",
    view: "Visualize",
    search: "Search folder",
    action: "Action",
    interrogacion: "?",
    close: "Close",
    detalle: "Details of file",
    nameFiles: "Name: ",
  },
  es: {
    number: "#",
    name: "Nombre de la carpeta",
    addFolder: "Agregar carpeta",
    cancel: "Cancelar",
    delete: "Eliminar",
    description: "Descripción",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage: "¿Estás seguro de que quieres eliminar la carpeta",
    more: "Más información",
    view: "Visualizar",
    search: "Buscar folder",
    action: "Acción",
    interrogacion: "?",
    close: "Cerrar",
    detalle: "Detalles del archivo",
    nameFiles: "Nombre: ",
  },
};

const FolderDashboard = () => {
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";
  const [folders, setFolders] = useState([]);
  const [searchFolder, setSearchFolder] = useState("");
  const { language } = useLanguage();
  const [deleteFolderName, setDeleteFolderName] = useState(null);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const cancelRef = useRef();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewedFile, setViewedFile] = useState(null);
  const [fileContent, setFileContent] = useState("");

  const fetchData = () => {
    axios
      .get(`${API_ENDPOINT_FOLDER}/getAllFolders`)
      .then((response) => {
        setFolders(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la carpeta ", error);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const handleDeleteFolder = (folderId, folderName) => {
    setDeleteFolderId(folderId);
    setDeleteFolderName(folderName);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteFolderId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${API_ENDPOINT_FOLDER}/deleteFolder/${deleteFolderId}`)
      .then((response) => {
        setDeleteFolderId(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error al eliminar la carpeta:", error);
      });
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const handleViewFile = async (fileName) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FOLDER}/getURLFile/${fileName}`
      );
      const fileDetails = response.data;
      setViewedFile(fileDetails);

      if (fileDetails.extension === "txt") {
        const content = await fetchFileContent(fileDetails.url);
        setFileContent(content);
      } else {
        setFileContent("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo:", error);
    }
  };

  const fetchFileContent = async (fileUrl) => {
    try {
      const textResponse = await fetch(fileUrl);
      const text = await textResponse.text();
      return text;
    } catch (error) {
      console.error("Error fetching text file content:", error);
      return "";
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          pb={4}
          justify="space-between"
        >
          <FormControl flex="1" mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 2 }}>
            <Flex direction={{ base: "column", md: "row" }}>
              <Skeleton isLoaded={!loading}>
                <Flex direction={{ base: "column", md: "row" }} w="100%">
                  <InputGroup
                    maxW={{ base: "100%", md: "300px" }}
                    mr={{ base: 0, md: 2 }}
                  >
                    <Input
                      type="text"
                      name="name"
                      placeholder={content[language].search}
                      value={searchFolder}
                      onChange={(e) => setSearchFolder(e.target.value)}
                    />
                  </InputGroup>
                </Flex>
              </Skeleton>
            </Flex>
          </FormControl>
          <Skeleton isLoaded={!loading}>
            <Button
              bg={"gray.300"}
              color="black"
              _hover={{
                bg: "gray.400",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              w={{ base: "auto", md: "auto" }}
              mt={{ base: 2, md: 0 }}
              onClick={handleIconClick}
            >
              {content[language].addFolder}
            </Button>
          </Skeleton>
        </Flex>

        <Skeleton isLoaded={!loading}>
          <TableContainer
            style={{
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            }}
            pb={4}
          >
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>{content[language].number}</Th>
                  <Th>{content[language].name}</Th>
                  <Th>{content[language].description}</Th>
                  <Th>{content[language].action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {folders
                  .filter((folder) => {
                    const folderName = folder.name.toLowerCase();
                    const searchLower = searchFolder.toLowerCase();
                    return folderName.includes(searchLower);
                  })
                  .map((folder, index) => (
                    <React.Fragment key={folder.id}>
                      <Tr key={index}>
                        <Td>
                          <Box fontWeight="bold">{index + 1}</Box>
                        </Td>
                        <Td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {folder.name}
                        </Td>
                        <Td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {folder.description}
                        </Td>

                        <Td>
                          <>
                            <Tooltip label={content[language].trash}>
                              <Button
                                colorScheme="red"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  handleDeleteFolder(folder.id, folder.name)
                                }
                              >
                                <BiTrash />
                              </Button>
                            </Tooltip>
                          </>

                          <Tooltip label={content[language].more}>
                            <Button
                              onClick={() => toggleAccordion(folder.id)}
                              colorScheme="gray"
                              size="sm"
                              mr={2}
                              border="1px solid black"
                            >
                              {activeAccordion === folder.id ? (
                                <BiChevronUp />
                              ) : (
                                <BiChevronDown />
                              )}
                            </Button>
                          </Tooltip>
                        </Td>
                      </Tr>
                      {activeAccordion === folder.id && (
                        <Tr>
                          <Td colSpan={4}>
                            <Box>
                              {JSON.parse(folder.files).map((file, idx) => (
                                <HStack key={idx} mt={2}>
                                  <Button
                                    size="sm"
                                    colorScheme="gray"
                                    leftIcon={<FaEye />}
                                    border="1px solid black"
                                    onClick={() => handleViewFile(file)}
                                  >
                                    {content[language].view}
                                  </Button>
                                  <Text
                                    style={{
                                      maxWidth: "500px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {file}
                                  </Text>
                                </HStack>
                              ))}
                            </Box>
                          </Td>
                        </Tr>
                      )}
                    </React.Fragment>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>
      </Box>

      {deleteFolderId && (
        <AlertDialog
          isOpen={isDeleteConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmDeleteTitle}
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmDeleteMessage}{" "}
                <strong>{deleteFolderName}</strong>
                {content[language].interrogacion}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelDelete}>
                  {content[language].cancel}
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  {content[language].delete}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      <ModalAddFolder
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        fetchData={fetchData}
      />

      {viewedFile && (
        <Modal isOpen={viewedFile !== null} onClose={() => setViewedFile(null)}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={5}>
                <strong>{content[language].nameFiles}</strong> {viewedFile.name}
              </Text>

              {viewedFile.extension === "pdf" && (
                <>
                  <PdfViewer url={viewedFile.url.trim()} />
                </>
              )}
              {viewedFile.extension === "docx" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={viewedFile.url.trim()}
                    frameBorder="0"
                  ></iframe>
                </>
              )}
              {viewedFile.extension === "pptx" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={viewedFile.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.extension === "xlsx" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={viewedFile.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFile.extension === "jpg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFile.url.trim()}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.extension === "ico" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFile.url.trim()}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.extension === "png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFile.url.trim()}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.extension === "webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFile.url.trim()}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.extension === "mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source src={viewedFile.url.trim()} type="video/mp4" />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFile.extension === "txt" && (
                <Box>
                  <Text>{fileContent}</Text>
                </Box>
              )}
              {viewedFile.extension === "dwg" && (
                <>
                  <PdfViewer url={viewedFile.url.trim()} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFile(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ChakraProvider>
  );
};

export default FolderDashboard;
