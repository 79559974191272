import React, { useState, useEffect, useCallback } from "react";
import {
  ChakraProvider,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  HStack,
  Text,
  Stack,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  AspectRatio,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import { FaEye } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import PdfViewer from "./PdfViewer";
import { InfoIcon } from "@chakra-ui/icons";

const content = {
  en: {
    reason: "Reason",
    name: "Full name",
    add: "Generate new email",
    email: "Email",
    file: "Files",
    action: "Action",
    search: "Search user",
    view: "See file",
    cancel: "Cancel",
    delete: "Delete",
    more: "View files",
    type: "Type of file",
    selectFile: "Type of file",
    interrogacion: "?",
    nameFiles: "Name:",
    desFile: "Description:",
    typeFile: "File type:",
    close: "Close",
    detalle: "File details",
  },

  es: {
    reason: "Motivo",
    name: "Nombre completo",
    add: "Generar nuevo email",
    email: "Correo electrónico",
    file: "Archivos",
    action: "Acción",
    search: "Buscar usuario",
    view: "Ver archivo",
    cancel: "Cancelar",
    delete: "Eliminar",
    more: "Ver archivos",
    type: "Tipo de archivo",
    selectFile: "Tipo de archivo",
    interrogacion: "?",
    nameFiles: "Nombre:",
    desFile: "Descripción:",
    typeFile: "Tipo del archivo:",
    close: "Cerrar",
    detalle: "Detalles del archivo",
  },
};

const DashboardClient = () => {
  const API_ENDPOINT_USERSFILES = "https://api-siesa.in/api/v1/usersfiles";
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";
  const { language } = useLanguage();
  const [userFiles, setUserFiles] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [expandedFiles, setExpandedFiles] = useState([]);
  const [activeAccordionFile, setActiveAccordionFile] = useState(null);
  const [expandedFilesFolder, setExpandedFilesFolder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewedFile, setViewedFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [viewedFileFolder, setViewedFileFolder] = useState(null);
  const [fileContentFolder, setFileContentFolder] = useState("");

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const userEmail = decodedToken.email;

  const toast = useToast();

  const fetchUserFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT_USERSFILES}/getAll`);
      setUserFiles(response.data);
    } catch (error) {
      console.error("Error al recuperar archivos de usuario: ", error);
    }
  }, []);

  useEffect(() => {
    fetchUserFiles();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [fetchUserFiles]);

  const toggleAccordion = async (userId, reason) => {
    const isActive = activeAccordion === `${userId}-${reason}`;

    if (isActive) {
      setActiveAccordion(null);
      setExpandedFiles([]);
    } else {
      setActiveAccordion(`${userId}-${reason}`);

      try {
        const response = await axios.get(
          `${API_ENDPOINT_USERSFILES}/getUserFiles/${userId}/${reason}`
        );
        if (response.data.length === 0) {
          console.log(response.data);
          setExpandedFiles([{ message: "No hay archivos" }]);
        } else {
          setExpandedFiles(response.data);
        }
      } catch (error) {
        console.error("Error al recuperar archivos de usuario: ", error);
      }
    }
  };

  const handleViewFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FILE}/getURLFile/${fileId}`
      );
      const fileData = response.data;
      setViewedFile(fileData);

      const fileContent = await fetchFileContent(fileData);
      if (fileContent) {
        setFileContent(fileContent);
      } else {
        setViewedFile(fileData);
        setFileContent("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo: ", error);
    }
  };

  const fetchFileContent = async (fileData) => {
    if (fileData.type === "text/plain") {
      const textResponse = await fetch(getUrl(fileData.url.trim()));
      const text = await textResponse.text();
      return text;
    }
    return null;
  };

  const toggleAccordionFile = async (folderId) => {
    const isActive = activeAccordionFile === `${folderId}`;

    if (isActive) {
      setActiveAccordionFile(null);
      setExpandedFilesFolder([]);
    } else {
      setActiveAccordionFile(`${folderId}`);

      try {
        const response = await axios.get(
          `${API_ENDPOINT_FOLDER}/getFilesByFolder/${folderId}`
        );

        if (response.data.length === 0) {
          console.log(response.data);
          setExpandedFilesFolder([{ message: "No hay archivos" }]);
        } else {
          setExpandedFilesFolder(response.data);
        }
      } catch (error) {
        console.error("Error al recuperar archivos de la carpeta: ", error);
      }
    }
  };

  const handleViewFileFolder = async (fileName) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FOLDER}/getURLFile/${fileName}`
      );
      const fileDetails = response.data;
      setViewedFileFolder(fileDetails);

      if (fileDetails.extension === "txt") {
        const content = await fetchFileContentFolder(fileDetails.url);
        setFileContentFolder(content);
      } else {
        setFileContentFolder("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo:", error);
    }
  };

  const fetchFileContentFolder = async (fileUrl) => {
    try {
      const textResponse = await fetch(fileUrl);
      const text = await textResponse.text();
      return text;
    } catch (error) {
      console.error("Error fetching text file content:", error);
      return "";
    }
  };

  const getUrl = (file) => {
    return `https://api-siesa.in/archivos/${file}`;
  };

  return (
    <ChakraProvider>
      <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Skeleton isLoaded={!loading}>
          <Wrap marginBottom="1rem">
            <WrapItem>
              <Tooltip placement="top">
                <Button
                  borderColor="black"
                  borderWidth="2px"
                  onClick={() => {
                    toast({
                      title: userEmail,
                      status: "info",
                      isClosable: true,
                    });
                  }}
                >
                  <Text mb="0.5rem">
                    <InfoIcon />
                  </Text>{" "}
                </Button>
              </Tooltip>
            </WrapItem>
          </Wrap>
        </Skeleton>

        <Skeleton isLoaded={!loading}>
          <TableContainer
            style={{
              boxShadow:
                "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            }}
            pb={4}
          >
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>{content[language].reason}</Th>
                  <Th>{content[language].name}</Th>
                  <Th>{content[language].email}</Th>
                  <Th>{content[language].file}</Th>
                  <Th>{content[language].action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userFiles
                  .filter((userFile) => userFile.email === userEmail)
                  .map((userFile) => (
                    <React.Fragment key={userFile.iduserfile}>
                      <Tr key={userFile.iduserfile}>
                        <Td>{userFile.reason}</Td>
                        <Td>{userFile.full_name}</Td>
                        <Td>{userFile.email}</Td>
                        <Td>{userFile.file_count}</Td>
                        <Td>
                          <>
                            <Tooltip label={content[language].more}>
                              <Button
                                colorScheme="gray"
                                size="sm"
                                mr={2}
                                onClick={() =>
                                  toggleAccordion(userFile.id, userFile.reason)
                                }
                                border="1px solid black"
                              >
                                {activeAccordion ===
                                `${userFile.id}-${userFile.reason}` ? (
                                  <BiChevronUp />
                                ) : (
                                  <BiChevronDown />
                                )}
                              </Button>
                            </Tooltip>
                          </>
                        </Td>
                      </Tr>
                      {activeAccordion ===
                        `${userFile.id}-${userFile.reason}` &&
                        expandedFiles && (
                          <>
                            {expandedFiles.files &&
                              expandedFiles.files.map(
                                (file) =>
                                  file && (
                                    <Tr key={file.id}>
                                      <Td colSpan={3}>
                                        <HStack>
                                          <Button
                                            size="sm"
                                            colorScheme="gray"
                                            leftIcon={<FaEye />}
                                            mt={{ base: 2, md: 0 }}
                                            border="1px solid black"
                                            onClick={() =>
                                              handleViewFile(file.id)
                                            }
                                          >
                                            {content[language].view}
                                          </Button>
                                          <Box w="10px" />
                                          <Stack spacing={1}>
                                            <Text
                                              style={{
                                                maxWidth: "400px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {content[language].nameFile}{" "}
                                              {file.name}
                                            </Text>
                                            <Text
                                              style={{
                                                maxWidth: "400px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {
                                                content[language]
                                                  .descriptionFile
                                              }{" "}
                                              {file.description}
                                            </Text>
                                          </Stack>
                                        </HStack>
                                      </Td>
                                    </Tr>
                                  )
                              )}

                            {expandedFiles.folders &&
                              expandedFiles.folders.map(
                                (folder) =>
                                  folder && (
                                    <React.Fragment key={folder.id}>
                                      <Tr key={folder.id}>
                                        <Td colSpan={3}>
                                          <HStack>
                                            <Button
                                              colorScheme="gray"
                                              size="sm"
                                              mr={2}
                                              onClick={() =>
                                                toggleAccordionFile(folder.id)
                                              }
                                              border="1px solid black"
                                            >
                                              {activeAccordionFile ===
                                              `${folder.id}` ? (
                                                <BiChevronUp />
                                              ) : (
                                                <BiChevronDown />
                                              )}
                                            </Button>

                                            <Box w="10px" />
                                            <Stack spacing={1}>
                                              <Text
                                                style={{
                                                  maxWidth: "400px",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {content[language].nameFolder}{" "}
                                                {folder.name}
                                              </Text>
                                              <Text
                                                style={{
                                                  maxWidth: "400px",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {
                                                  content[language]
                                                    .descriptionFolder
                                                }{" "}
                                                {folder.description}
                                              </Text>
                                            </Stack>
                                          </HStack>
                                        </Td>
                                      </Tr>
                                      {activeAccordionFile === `${folder.id}` &&
                                        expandedFilesFolder.files &&
                                        expandedFilesFolder.files.map(
                                          (file, index) => (
                                            <React.Fragment key={file.id}>
                                              <Tr>
                                                <Td colSpan={3}>
                                                  <HStack>
                                                    <Button
                                                      size="sm"
                                                      colorScheme="gray"
                                                      leftIcon={<FaEye />}
                                                      mt={{ base: 2, md: 0 }}
                                                      border="1px solid black"
                                                      onClick={() =>
                                                        handleViewFileFolder(
                                                          file.name
                                                        )
                                                      }
                                                    >
                                                      Ver
                                                    </Button>
                                                    <Box w="10px" />
                                                    <Stack spacing={1}>
                                                      <Text
                                                        style={{
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        Nombre: {file.name}
                                                      </Text>
                                                    </Stack>
                                                  </HStack>
                                                </Td>
                                              </Tr>
                                            </React.Fragment>
                                          )
                                        )}
                                    </React.Fragment>
                                  )
                              )}
                          </>
                        )}
                    </React.Fragment>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Skeleton>
      </Box>

      {viewedFile && (
        <Modal isOpen={viewedFile !== null} onClose={() => setViewedFile(null)}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>{content[language].nameFiles}</strong> {viewedFile.name}
              </Text>
              <Text mb={5}>
                <strong>{content[language].desFile} </strong>{" "}
                {viewedFile.description}
              </Text>
              {/* /////////////////////////////// */}
              {viewedFile.type === "application/msword" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  ></iframe>
                </>
              )}
              {viewedFile.type === "application/vnd.ms-powerpoint" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type === "application/vnd.ms-excel" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                <>
                  <Text>{getUrl(viewedFile.url.trim())}</Text>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {/* /////////////////////////// */}
              {viewedFile.type === "image/jpeg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "image/png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "video/mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={getUrl(viewedFile.url.trim())}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "text/plain" && (
                <Box>
                  <Text>{fileContent}</Text>
                </Box>
              )}
              {viewedFile.type === "image/webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "application/pdf" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
              {viewedFile.type === "application/octet-stream" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFile(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {viewedFileFolder && (
        <Modal
          isOpen={viewedFileFolder !== null}
          onClose={() => setViewedFileFolder(null)}
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={5}>
                <strong>{content[language].nameFiles}</strong>{" "}
                {viewedFileFolder.name}
              </Text>

              {viewedFileFolder.extension === "pdf" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
              {viewedFileFolder.extension === "docx" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  ></iframe>
                </>
              )}
              {viewedFileFolder.extension === "pptx" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "xlsx" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "jpg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "ico" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={viewedFileFolder.url.trim()}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "txt" && (
                <Box>
                  <Text>{fileContentFolder}</Text>
                </Box>
              )}
              {viewedFileFolder.extension === "dwg" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFileFolder(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ChakraProvider>
  );
};

export default DashboardClient;
