import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Checkbox,
  ChakraProvider,
  Textarea,
  Select,
  Progress,
  Box,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useLanguage } from "./LanguageContext";
import axios from "axios";

const Form1 = ({ formState, handleChange }) => {
  const API_ENDPOINT_USER = "https://api-siesa.in/api/v1/users";

  const { language } = useLanguage();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersResponse = await axios.get(
          `${API_ENDPOINT_USER}/user-details`
        );
        setUserData(usersResponse.data);
      } catch (error) {
        console.error("Error al recuperar los datos del usuario:", error);
      }
    };
    fetchUserData();
  }, []);

  const content = {
    en: {
      userFile: "Assign to user",
      reason: "Reason:",
      reasonDes: "Reason for assignment",
      user: "User:",
      selectUser: "Select user",
      errorMessage: "An error occurred while allocating files",
      requiredFieldsError: "Please fill in all required fields.",
    },
    es: {
      userFile: "Asignar a usuario",
      reason: "Motivo:",
      reasonDes: "Motivo de la asignación",
      user: "Usuario:",
      selectUser: "Seleccionar usuario",
      errorMessage: "Se produjo un error al asignar archivos",
      requiredFieldsError: "Por favor complete todos los campos requeridos.",
    },
  };

  return (
    <Flex direction="column" alignItems="center">
      <Heading textAlign="center" fontWeight="normal" mb="2%">
        {content[language].userFile}
      </Heading>
      <FormControl isRequired>
        <FormLabel htmlFor="reason">{content[language].reason}</FormLabel>
        <Textarea
          id="reason"
          name="reason"
          value={formState.reason}
          onChange={handleChange}
          placeholder={content[language].reasonDes}
        />
      </FormControl>

      <FormControl mt={4} isRequired>
        <FormLabel htmlFor="userId">{content[language].user}</FormLabel>
        <Select
          id="userId"
          name="userId"
          value={formState.userId}
          onChange={handleChange}
          placeholder={content[language].selectUser}
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
        >
          {userData.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name} - {user.email}
            </option>
          ))}
        </Select>
      </FormControl>
    </Flex>
  );
};

const Form2 = ({ formState, handleFileSelection }) => {
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";
  const [folders, setFolders] = useState([]);
  const { language } = useLanguage();

  const loadData = async () => {
    try {
      const foldersResponse = await axios.get(
        `${API_ENDPOINT_FOLDER}/getAllFolders`
      );
      setFolders(foldersResponse.data || []);
    } catch (error) {
      console.error("Error al cargar carpetas:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const content = {
    en: {
      folders: "Assign folders",
      errorMessage: "An error occurred while allocating folders",
      requiredFieldsError: "Please fill in all required fields.",
    },
    es: {
      folders: "Asignar carpetas",
      errorMessage: "Se produjo un error al asignar las carpetas",
      requiredFieldsError: "Por favor complete todos los campos requeridos.",
    },
  };

  return (
    <ChakraProvider>
      <Heading textAlign="center" fontWeight="normal" mb="2%">
        {content[language].folders}
      </Heading>
      <Box
        style={{
          maxHeight: "300px",
          overflowY: "scroll",
          padding: "1rem",
        }}
      >
        <Stack direction={{ base: "column", sm: "row" }} spacing={5}>
          <Stack spacing={5} direction="column" alignItems="flex-start">
            {folders.map((folder) => (
              <Checkbox
                colorScheme="blue"
                key={folder.id}
                value={folder.id}
                onChange={handleFileSelection}
              >
                {folder.name} - {folder.description}
              </Checkbox>
            ))}
          </Stack>
        </Stack>
      </Box>
    </ChakraProvider>
  );
};

export default function FormAddUserFile({ onClose }) {
  const API_ENDPOINT_USERFILE = "https://api-siesa.in/api/v1/usersfiles";
  const [isDisabled, setIsDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const { language } = useLanguage();
  const [formState, setFormState] = useState({
    reason: "",
    userId: "",
    folderIds: [],
  });

  const content = {
    en: {
      back: "Back",
      next: "Next",
      submit: "Add",
      filesAssignedSuccess: "Folders successfully assigned to user.",
      errorMessage: "An error occurred while allocating folders",
      requiredFieldsError: "Please fill in all required fields.",
    },
    es: {
      back: "Atrás",
      next: "Siguiente",
      submit: "Agregar",
      filesAssignedSuccess:
        "Las carpetas se asignaron correctamente al usuario.",
      errorMessage: "Se produjo un error al asignar carpetas",
      requiredFieldsError: "Por favor complete todos los campos requeridos.",
    },
  };

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileSelection = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormState((prevState) => ({
        ...prevState,
        folderIds: [...prevState.folderIds, value],
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        folderIds: prevState.folderIds.filter((id) => id !== value),
      }));
    }
  };

  useEffect(() => {
    setIsDisabled(formState.folderIds.length === 0);
  }, [formState.folderIds]);

  const handleNextStep = () => {
    if (step === 1) {
      if (formState.reason && formState.userId) {
        setStep(step + 1);
      } else {
        toast({
          title: "Error",
          description: content[language].requiredFieldsError,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      await axios.post(`${API_ENDPOINT_USERFILE}/addUserFolders`, {
        reason: formState.reason,
        userId: formState.userId,
        folderIds: formState.folderIds,
      });
      toast({
        title: "Success",
        description: content[language].filesAssignedSuccess,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: content[language].errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Flex direction="column" align="center" justify="center" p={8}>
        <Box width="100%" maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <Progress
            value={(step / 2) * 100}
            mb="4%"
            mx="10%"
            hasStripe
            isAnimated
            size="sm"
          />
          {step === 1 ? (
            <Form1 formState={formState} handleChange={handleChange} />
          ) : (
            <Form2
              formState={formState}
              handleFileSelection={handleFileSelection}
            />
          )}
          <ButtonGroup mt="5%" w="100%">
            <Flex w="100%" justifyContent="space-between">
              <Flex>
                <Button
                  onClick={handlePreviousStep}
                  isDisabled={step === 1}
                  colorScheme="gray"
                  variant="outline"
                  w="7rem"
                  mr="5%"
                >
                  {content[language].back}
                </Button>
                <Button
                  onClick={step === 2 ? handleSubmit : handleNextStep}
                  colorScheme="blue"
                  w="7rem"
                  isDisabled={step === 2 ? isDisabled : false}
                >
                  {step === 2
                    ? content[language].submit
                    : content[language].next}
                </Button>
              </Flex>
            </Flex>
          </ButtonGroup>
        </Box>
      </Flex>
    </ChakraProvider>
  );
}
