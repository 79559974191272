// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light", // Inicialmente en modo claro
  useSystemColorMode: false,  // Usa el modo de color del sistema si está disponible
};

const theme = extendTheme({ config });

export default theme;