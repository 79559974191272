import React from "react";
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from "@chakra-ui/react";
import Register from "./Register";
import { useLanguage } from "./LanguageContext";

export default function ModalRegister({ isOpen, onClose }) {
  const [isSmallerThanMd] = useMediaQuery("(max-width: 768px)");

  const { language } = useLanguage();

  const content = {
    en: {
      buttonClose: "Close",
    },
    es: {
      buttonClose: "Cerrar",
    },
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isSmallerThanMd}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent /* maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }} */>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Register onClose={onClose} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{content[language].buttonClose}</Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
