import React from "react";

const PdfViewer = ({ url }) => {
  return (
    <div style={{ width: "100%", height: "600px" }}>
      <iframe src={url} title="PDF Viewer" width="100%" height="100%" frameBorder="0" />
    </div>
  );
};

export default PdfViewer;

