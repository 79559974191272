import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleDiscipline: "Add File",
    name: "Name of file:",
    description: "Description of file:",
    uploadFiles: "Upload file:",
    cancel: "Cancel",
    save: "Save",
    titleAdd: "File added",
    selectDiscipline: "Discipline of file:",
    selectDisciplines: "Select a discipline",
    errors: {
      validation: "Please select values for all options.",
      add: "Error adding the file. Please try again.",
      nameRequired: "Please enter the name of the file.",
      descriptionRequired: "Please enter the deescription of the file.",
      disciplineRequired: "Please select a discipline for the file.",
      fileRequired: "Please upload at least one file.",
    },
    success: {
      add: "The file has been added successfully.",
    },
    remove: "Remove",
    fileDescription: "Description of the file to load",
    fileName: "Name of the file to load",
  },
  es: {
    titleDiscipline: "Agregar Archivo",
    name: "Nombre del archivo:",
    description: "Descripción del archivo:",
    uploadFiles: "Subir archivo:",
    cancel: "Cancelar",
    save: "Guardar",
    titleAdd: "Archivo agregado",
    selectDiscipline: "Disciplina del archivo:",
    selectDisciplines: "Seleccione una disciplina",
    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      add: "Error al agregar el archivo. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, ingrese el nombre del archivo.",
      descriptionRequired: "Por favor, ingrese la descripción del archivo.",
      disciplineRequired:
        "Por favor, seleccione una disciplina para el archivo.",
      fileRequired: "Por favor, cargue al menos un archivo.",
    },
    success: {
      add: "El archivo se ha agregado correctamente.",
    },
    remove: "Eliminar",
    fileDescription: "Descripción del archivo a cargar",
    fileName: "Nombre del archivo a cargar",
  },
};

const ModalAddFile = ({ isOpen, onClose, fetchData }) => {
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_DISCIPLINE = "https://api-siesa.in/api/v1/disciplines";

  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    disciplineId: "",
    file: null,
  });
  const [loading, setLoading] = useState(false);
  const [disciplineOptions, setDisciplineOptions] = useState([]);

  const fetchDisciplineDetails = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_DISCIPLINE}/getAllDisciplines`
      );
      setDisciplineOptions(response.data);
    } catch (error) {
      console.error("Error fetching discipline details:", error);
    }
  };

  useEffect(() => {
    fetchDisciplineDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData((prevData) => ({
        ...prevData,
        file: selectedFile,
      }));
    }
  };

  const addFile = async () => {
    try {
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.description) {
        toast({
          title: "Error",
          description: content[language].errors.descriptionRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.disciplineId) {
        toast({
          title: "Error",
          description: content[language].errors.disciplineRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.file) {
        toast({
          title: "Error",
          description: content[language].errors.fileRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("DisciplineId", formData.disciplineId);
      formDataToSend.append("file", formData.file);

      const response = await axios.post(
        `${API_ENDPOINT_FILE}/addFile`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: content[language].titleAdd,
          description: content[language].success.add,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setFormData({
          name: "",
          description: "",
          disciplineId: "",
          file: null,
        });

        onClose && onClose();
        fetchData && fetchData();
      } else {
        throw new Error("Error adding file");
      }
    } catch (error) {
      console.error("Error adding file:", error);
      toast({
        title: "Error",
        description: content[language].errors.add,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData({
      name: "",
      description: "",
      disciplineId: "",
      file: null,
    });

    onClose && onClose();
  };

  return (
    <ChakraProvider >
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleDiscipline}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={content[language].fileName}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="description">
                  {content[language].description}
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder={content[language].fileDescription}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="disciplineId">
                  {content[language].selectDiscipline}
                </FormLabel>
                <Select
                  id="disciplineId"
                  name="disciplineId"
                  placeholder={content[language].selectDisciplines}
                  value={formData.disciplineId}
                  onChange={handleChange}
                >
                  {disciplineOptions.map((discipline) => (
                    <option key={discipline.id} value={discipline.id}>
                      {discipline.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="file">
                  {content[language].uploadFiles}
                </FormLabel>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={handleClose}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={addFile}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAddFile;
