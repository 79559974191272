import React, { useState, useEffect, useRef } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  HStack,
  Text,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  AspectRatio,
} from "@chakra-ui/react";
import {
  BiPlus,
  BiTrash,
  BiMailSend,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import axios from "axios";
import { useLanguage } from "../componets/LanguageContext";
import { FaEye } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
/* import QRCode from "qrcode.react"; */
import ModalAddUserFile from "../componets/ModalAddUserFile";
import ModalAddUserFolder from "../componets/ModalAddUserFolder";
import PdfViewer from "./PdfViewer";

const content = {
  en: {
    reason: "Reason",
    name: "Full name",
    add: "Generate email from files",
    addFolder: "Generate email from folders",
    email: "Email",
    file: "Files/Folders",
    folder: "Folders",
    action: "Action",
    search: "Search user",
    view: "See file",
    cancel: "Cancel",
    delete: "Delete",
    more: "View files",
    trash: "Delete",
    send: "Send email with attached files",
    sendEmail: "Send",
    sendEmailLink: "Send email with the link to view the files on the web",
    nameFile: "Name file: ",
    descriptionFile: "File description: ",
    nameFolder: "Name folder: ",
    descriptionFolder: "Folder description: ",
    confirmSendTitle: "Confirm sending files",
    confirmSendMessage:
      "Are you sure you want to send this email with the attachments?",
    confirmSendTitleLink: "Confirm sending access link",
    confirmSendMessageLink:
      "Are you sure you want to send this email with the login links?",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteMessage:
      "Are you sure you want to delete this user with his reason files ",
    interrogacion: "?",
    detalle: "File details",
    nameFiles: "Name:",
    desFile: "Description:",
    type: "File type:",
    close: "Close",
    list: "List of files",
  },

  es: {
    reason: "Motivo",
    name: "Nombre completo",
    add: "Generar email de archivos",
    addFolder: "Generar email de carpetas",
    email: "Correo electrónico",
    file: "Archivos/Carpetas",
    folder: "Carpetas",
    action: "Acción",
    search: "Buscar usuario",
    view: "Ver archivo",
    cancel: "Cancelar",
    delete: "Eliminar",
    more: "Ver archivos",
    trash: "Eliminar",
    send: "Enviar correo electrónico con los archivos adjuntados",
    sendEmail: "Enviar",
    sendEmailLink:
      "Enviar correo electrónico con el link para ver los archivos en la web",
    nameFile: "Nombre del archivo: ",
    descriptionFile: "Descripción del archivo: ",
    nameFolder: "Nombre de la carpeta: ",
    descriptionFolder: "Descripción de la carpeta: ",
    confirmSendTitle: "Confirmar envío de archivos",
    confirmSendMessage:
      "¿Está seguro de que desea enviar este correo electrónico con los archivos adjuntos?",
    confirmSendTitleLink: "Confirmar envío de enlace de acceso",
    confirmSendMessageLink:
      "¿Está seguro de que desea enviar este correo electrónico con los enlaces de inicio de sesión?",
    confirmDeleteTitle: "Confirmar eliminación",
    confirmDeleteMessage:
      "¿Estás seguro de que quieres eliminar este usuario con sus archivos de razon ",
    interrogacion: "?",
    detalle: "Detalles del archivo",
    nameFiles: "Nombre:",
    desFile: "Descripción:",
    type: "Tipo del archivo:",
    close: "Cerrar",
    list: "Lista de archivos",
  },
};

const TableUser = () => {
  const API_ENDPOINT_USERSFILES = "https://api-siesa.in/api/v1/usersfiles";
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_FOLDER = "https://api-siesa.in/api/v1/folders";
  const { language } = useLanguage();
  const [userFiles, setUserFiles] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [expandedFiles, setExpandedFiles] = useState([]);
  const [activeAccordionFile, setActiveAccordionFile] = useState(null);
  const [expandedFilesFolder, setExpandedFilesFolder] = useState([]);
  const cancelRef = useRef();
  const [isSendConfirmationModalOpen, setIsSendConfirmationModalOpen] =
    useState(false);
  const [isSendLinkConfirmationModalOpen, setIsSendLinkConfirmationModalOpen] =
    useState(false);
  const [sendUserFileId, setSendUserFileId] = useState(null);
  const [sendLinkUserFileId, setSendLinkUserFileId] = useState(null);
  /*const [infoEmailLinkUserFileId, setInfoEmailLinkUserFileId] = useState(null);
   const [filesLink, setFilesLink] = useState([]); */
  const [isLoading, setIsLoading] = useState(false);
  const [deleteUserFileId, setDeleteUserFileId] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isDeleteUserFileReason, setIsDeleteUserFileReason] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFolder, setIsModalOpenFolder] = useState(false);
  const [viewedFile, setViewedFile] = useState(null);
  const [viewedFileFolder, setViewedFileFolder] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [fileContentFolder, setFileContentFolder] = useState("");

  const fetchUserFiles = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT_USERSFILES}/getAll`);
      setUserFiles(response.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error al recuperar archivos de usuario: ", error);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchUserFiles();
  }, []);

  const handleDeleteUser = (id, reason) => {
    setDeleteUserFileId(id);
    setIsDeleteUserFileReason(reason);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteUserFileId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteFile = () => {
    axios
      .delete(
        `${API_ENDPOINT_USERSFILES}/delete/${deleteUserFileId}/${isDeleteUserFileReason}`
      )
      .then((response) => {
        setDeleteUserFileId(null);
        setIsDeleteUserFileReason(null);
        setIsDeleteConfirmationModalOpen(false);
        fetchUserFiles();
      })
      .catch((error) => {
        console.error("Error al eliminar usuario: ", error);
      });
  };

  /* Para enviar email con archivos adjuntados */

  const handleSend = (userFileEmail, userFileReason) => {
    setSendUserFileId({ email: userFileEmail, reason: userFileReason });
    setIsSendConfirmationModalOpen(true);
  };

  const handleCancelSend = () => {
    setSendUserFileId(null);
    setIsSendConfirmationModalOpen(false);
  };

  const handleConfirmSend = () => {
    setIsLoading(true);
    const { email, reason } = sendUserFileId;
    axios
      .post(`${API_ENDPOINT_USERSFILES}/getUserFiles/send-email/${email}`, {
        reason,
      })
      .then((response) => {
        setIsSendConfirmationModalOpen(false);
        setIsLoading(false);
        fetchUserFiles();
      })
      .catch((error) => {
        console.error("Error al enviar correo electrónico: ", error);
        setIsLoading(false);
      });
  };

  /* Enviar email con link de acceso a ver los archivso */

  const handleSendLink = (userFile) => {
    setSendLinkUserFileId({ email: userFile.email, reason: userFile.reason });
    /* setInfoEmailLinkUserFileId(userFile); */
    setIsSendLinkConfirmationModalOpen(true);
  };

  const handleCancelSendLink = () => {
    setSendLinkUserFileId(null);
    setIsSendLinkConfirmationModalOpen(false);
  };

  const handleConfirmSendLink = () => {
    setIsLoading(true);
    const { email, reason } = sendLinkUserFileId;

    axios
      .post(
        `${API_ENDPOINT_USERSFILES}/getUserFiles/send-email-link/${email}`,
        {
          reason,
        }
      )
      .then((response) => {
        setIsSendLinkConfirmationModalOpen(false);
        setIsLoading(false);
        fetchUserFiles();
      })
      .catch((error) => {
        console.error("Error al enviar correo electrónico: ", error);
        setIsLoading(false);
      });
  };

  const toggleAccordion = async (userId, reason) => {
    const isActive = activeAccordion === `${userId}-${reason}`;

    if (isActive) {
      setActiveAccordion(null);
      setExpandedFiles([]);
    } else {
      setActiveAccordion(`${userId}-${reason}`);

      try {
        const response = await axios.get(
          `${API_ENDPOINT_USERSFILES}/getUserFiles/${userId}/${reason}`
        );
        if (response.data.length === 0) {
          console.log(response.data);
          setExpandedFiles([{ message: "No hay archivos" }]);
        } else {
          setExpandedFiles(response.data);
        }
      } catch (error) {
        console.error("Error al recuperar archivos de usuario: ", error);
      }
    }
  };

  const handleConsultFiles = async (userFile) => {
    try {
      handleSendLink(userFile);
    } catch (error) {
      console.error("Error al recuperar archivos de usuario: ", error);
    }
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const handleIconClickFolder = () => {
    setIsModalOpenFolder(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchUserFiles();
  };

  const handleCloseModalFolder = () => {
    setIsModalOpenFolder(false);
    fetchUserFiles();
  };

  const handleViewFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FILE}/getURLFile/${fileId}`
      );
      const fileData = response.data;
      setViewedFile(fileData);

      const fileContent = await fetchFileContent(fileData);
      if (fileContent) {
        setFileContent(fileContent);
      } else {
        setViewedFile(fileData);
        setFileContent("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo: ", error);
    }
  };

  const fetchFileContent = async (fileData) => {
    if (fileData.type === "text/plain") {
      const textResponse = await fetch(getUrl(fileData.url.trim()));
      const text = await textResponse.text();
      return text;
    }
    return null;
  };

  const toggleAccordionFile = async (folderId) => {
    const isActive = activeAccordionFile === `${folderId}`;

    if (isActive) {
      setActiveAccordionFile(null);
      setExpandedFilesFolder([]);
    } else {
      setActiveAccordionFile(`${folderId}`);

      try {
        const response = await axios.get(
          `${API_ENDPOINT_FOLDER}/getFilesByFolder/${folderId}`
        );

        if (response.data.length === 0) {
          console.log(response.data);
          setExpandedFilesFolder([{ message: "No hay archivos" }]);
        } else {
          setExpandedFilesFolder(response.data);
        }
      } catch (error) {
        console.error("Error al recuperar archivos de la carpeta: ", error);
      }
    }
  };

  const handleViewFileFolder = async (fileName) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT_FOLDER}/getURLFile/${fileName}`
      );
      const fileDetails = response.data;
      setViewedFileFolder(fileDetails);

      if (fileDetails.extension === "txt") {
        const content = await fetchFileContentFolder(fileDetails.url);
        setFileContentFolder(content);
      } else {
        setFileContentFolder("");
      }
    } catch (error) {
      console.error("Error al recuperar el archivo:", error);
    }
  };

  const fetchFileContentFolder = async (fileUrl) => {
    try {
      const textResponse = await fetch(fileUrl);
      const text = await textResponse.text();
      return text;
    } catch (error) {
      console.error("Error fetching text file content:", error);
      return "";
    }
  };

  const getUrl = (file) => {
    return `https://api-siesa.in/archivos/${file}`;
  };

  return (
    <ChakraProvider>
      {/*   <Box
        style={{
          maxHeight: "100%",
          overflowY: "scroll",
          padding: "1rem",
        }}
      > */}
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        pb={4}
        justify="end"
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={2}
          align="center"
        >
          <Skeleton isLoaded={!loading}>
            <Button
              bg={"gray.300"}
              color="black"
              _hover={{
                bg: "gray.400",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              w={{ base: "auto", md: "auto" }}
              mt={{ base: 2, md: 0 }}
              onClick={handleIconClick}
            >
              {content[language].add}
            </Button>
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <Button
              bg={"gray.300"}
              color="black"
              _hover={{
                bg: "gray.400",
              }}
              borderColor="black"
              borderWidth="2px"
              leftIcon={<BiPlus />}
              w={{ base: "auto", md: "auto" }}
              mt={{ base: 2, md: 0 }}
              onClick={handleIconClickFolder}
            >
              {content[language].addFolder}
            </Button>
          </Skeleton>
        </Stack>
      </Flex>

      <Skeleton isLoaded={!loading}>
        <TableContainer
          style={{
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          }}
          pb={4}
        >
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>{content[language].reason}</Th>
                <Th>{content[language].name}</Th>
                <Th>{content[language].email}</Th>
                <Th>{content[language].file}</Th>
                <Th>{content[language].action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userFiles.map((userFile) => (
                <React.Fragment key={userFile.iduserfile}>
                  <Tr key={userFile.iduserfile}>
                    <Td>{userFile.reason}</Td>
                    <Td>{userFile.full_name}</Td>
                    <Td>{userFile.email}</Td>
                    <Td>{userFile.file_count}</Td>
                    <Td>
                      <>
                        <Tooltip label={content[language].trash}>
                          <Button
                            colorScheme="red"
                            size="sm"
                            mr={2}
                            onClick={() =>
                              handleDeleteUser(userFile.id, userFile.reason)
                            }
                          >
                            <BiTrash />
                          </Button>
                        </Tooltip>
                        <Tooltip label={content[language].send}>
                          <Button
                            colorScheme="blue"
                            size="sm"
                            mr={2}
                            onClick={() => {
                              handleSend(userFile.email, userFile.reason);
                            }}
                          >
                            <BiMailSend />
                          </Button>
                        </Tooltip>
                        <Tooltip label={content[language].sendEmailLink}>
                          <Button
                            colorScheme="green"
                            size="sm"
                            mr={2}
                            onClick={() => {
                              handleConsultFiles(userFile);
                            }}
                          >
                            <FaLink />
                          </Button>
                        </Tooltip>
                        <Tooltip label={content[language].more}>
                          <Button
                            colorScheme="gray"
                            size="sm"
                            mr={2}
                            onClick={() =>
                              toggleAccordion(userFile.id, userFile.reason)
                            }
                            border="1px solid black"
                          >
                            {activeAccordion ===
                            `${userFile.id}-${userFile.reason}` ? (
                              <BiChevronUp />
                            ) : (
                              <BiChevronDown />
                            )}
                          </Button>
                        </Tooltip>
                      </>
                    </Td>
                  </Tr>
                  {activeAccordion === `${userFile.id}-${userFile.reason}` &&
                    expandedFiles && (
                      <>
                        {expandedFiles.files &&
                          expandedFiles.files.map(
                            (file) =>
                              file && (
                                <Tr key={file.id}>
                                  <Td colSpan={3}>
                                    <HStack>
                                      <Button
                                        size="sm"
                                        colorScheme="gray"
                                        leftIcon={<FaEye />}
                                        mt={{ base: 2, md: 0 }}
                                        border="1px solid black"
                                        onClick={() => handleViewFile(file.id)}
                                      >
                                        {content[language].view}
                                      </Button>
                                      <Box w="10px" />
                                      <Stack spacing={1}>
                                        <Text
                                          style={{
                                            maxWidth: "400px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {content[language].nameFile}{" "}
                                          {file.name}
                                        </Text>
                                        <Text
                                          style={{
                                            maxWidth: "400px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {content[language].descriptionFile}{" "}
                                          {file.description}
                                        </Text>
                                      </Stack>
                                    </HStack>
                                  </Td>
                                </Tr>
                              )
                          )}

                        {expandedFiles.folders &&
                          expandedFiles.folders.map(
                            (folder) =>
                              folder && (
                                <React.Fragment key={folder.id}>
                                  <Tr key={folder.id}>
                                    <Td colSpan={3}>
                                      <HStack>
                                        <Button
                                          colorScheme="gray"
                                          size="sm"
                                          mr={2}
                                          onClick={() =>
                                            toggleAccordionFile(folder.id)
                                          }
                                          border="1px solid black"
                                        >
                                          {activeAccordionFile ===
                                          `${folder.id}` ? (
                                            <BiChevronUp />
                                          ) : (
                                            <BiChevronDown />
                                          )}
                                        </Button>

                                        <Box w="10px" />
                                        <Stack spacing={1}>
                                          <Text
                                            style={{
                                              maxWidth: "400px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {content[language].nameFolder}{" "}
                                            {folder.name}
                                          </Text>
                                          <Text
                                            style={{
                                              maxWidth: "400px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {
                                              content[language]
                                                .descriptionFolder
                                            }{" "}
                                            {folder.description}
                                          </Text>
                                        </Stack>
                                      </HStack>
                                    </Td>
                                  </Tr>
                                  {activeAccordionFile === `${folder.id}` &&
                                    expandedFilesFolder.files &&
                                    expandedFilesFolder.files.map(
                                      (file, index) => (
                                        <React.Fragment key={file.id}>
                                          <Tr>
                                            <Td colSpan={3}>
                                              <HStack>
                                                <Button
                                                  size="sm"
                                                  colorScheme="gray"
                                                  leftIcon={<FaEye />}
                                                  mt={{ base: 2, md: 0 }}
                                                  border="1px solid black"
                                                  onClick={() =>
                                                    handleViewFileFolder(
                                                      file.name
                                                    )
                                                  }
                                                >
                                                  Ver
                                                </Button>
                                                <Box w="10px" />
                                                <Stack spacing={1}>
                                                  <Text
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    Nombre: {file.name}
                                                  </Text>
                                                </Stack>
                                              </HStack>
                                            </Td>
                                          </Tr>
                                        </React.Fragment>
                                      )
                                    )}
                                </React.Fragment>
                              )
                          )}
                      </>
                    )}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Skeleton>

      <ModalAddUserFile isOpen={isModalOpen} onClose={handleCloseModal} />

      <ModalAddUserFolder
        isOpen={isModalOpenFolder}
        onClose={handleCloseModalFolder}
      />

      {sendUserFileId && (
        <AlertDialog
          isOpen={isSendConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelSend}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmSendTitle}
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmSendMessage}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelSend}>
                  {content[language].cancel}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={handleConfirmSend}
                  ml={3}
                  isLoading={isLoading}
                >
                  {content[language].sendEmail}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {sendLinkUserFileId && (
        <AlertDialog
          isOpen={isSendLinkConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelSendLink}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmSendTitleLink}
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmSendMessageLink}

                {/*    <HStack align="start" spacing={4} p={4}>
                  <Box>
                    <QRCode
                      value={JSON.stringify({
                        id: infoEmailLinkUserFileId?.id,
                        email: infoEmailLinkUserFileId?.email,
                        name: infoEmailLinkUserFileId?.full_name,
                        reason: infoEmailLinkUserFileId?.reason,
                         files: filesLink.map((file) => ({
                          name: file.name,
                          description: file.description,
                          file: file.file,
                          type: file.type,
                        })), 
                      })}
                      size={200}
                    />
                  </Box>
                </HStack> */}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelSendLink}>
                  {content[language].cancel}
                </Button>
                <Button
                  colorScheme="green"
                  onClick={handleConfirmSendLink}
                  ml={3}
                  isLoading={isLoading}
                >
                  {content[language].sendEmail}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {deleteUserFileId && (
        <AlertDialog
          isOpen={isDeleteConfirmationModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCancelDelete}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {content[language].confirmDeleteTitle}
              </AlertDialogHeader>

              <AlertDialogBody>
                {content[language].confirmDeleteMessage}{" "}
                <strong>{isDeleteUserFileReason}</strong>
                {content[language].interrogacion}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancelDelete}>
                  {content[language].cancel}
                </Button>
                <Button colorScheme="red" onClick={handleDeleteFile} ml={3}>
                  {content[language].delete}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {viewedFile && (
        <Modal isOpen={viewedFile !== null} onClose={() => setViewedFile(null)}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>{content[language].nameFiles}</strong> {viewedFile.name}
              </Text>
              <Text mb={5}>
                <strong>{content[language].desFile} </strong>{" "}
                {viewedFile.description}
              </Text>
              {/* /////////////////////////////// */}
              {viewedFile.type === "application/msword" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  ></iframe>
                </>
              )}
              {viewedFile.type === "application/vnd.ms-powerpoint" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type === "application/vnd.ms-excel" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {viewedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                <>
                  <Text>{getUrl(viewedFile.url.trim())}</Text>
                  <iframe
                    title="Excel Document Preview"
                    src={getUrl(viewedFile.url.trim())}
                    frameborder="0"
                  />
                </>
              )}
              {/* /////////////////////////// */}
              {viewedFile.type === "image/jpeg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "image/png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "video/mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={getUrl(viewedFile.url.trim())}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "text/plain" && (
                <Box>
                  <Text>{fileContent}</Text>
                </Box>
              )}
              {viewedFile.type === "image/webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={getUrl(viewedFile.url.trim())}
                      alt={viewedFile.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFile.type === "application/pdf" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
              {viewedFile.type === "application/octet-stream" && (
                <>
                  <PdfViewer url={getUrl(viewedFile.url.trim())} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFile(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {viewedFileFolder && (
        <Modal
          isOpen={viewedFileFolder !== null}
          onClose={() => setViewedFileFolder(null)}
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
            <ModalHeader>{content[language].detalle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={5}>
                <strong>{content[language].nameFiles}</strong>{" "}
                {viewedFileFolder.name}
              </Text>

              {viewedFileFolder.extension === "pdf" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
              {viewedFileFolder.extension === "docx" && (
                <>
                  <iframe
                    title="Word Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  ></iframe>
                </>
              )}
              {viewedFileFolder.extension === "pptx" && (
                <>
                  <iframe
                    title="Power point Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "xlsx" && (
                <>
                  <iframe
                    title="Excel Document Preview"
                    src={viewedFileFolder.url.trim()}
                    frameBorder="0"
                  />
                </>
              )}
              {viewedFileFolder.extension === "jpg" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "ico" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "png" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "webp" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={4 / 3}
                  >
                    <Image
                      src={viewedFileFolder.url.trim()}
                      alt={viewedFileFolder.name}
                      objectFit="contain"
                      style={{
                        objectFit: "contain",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "mp4" && (
                <>
                  <AspectRatio
                    maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
                    ratio={16 / 9}
                  >
                    <video controls>
                      <source
                        src={viewedFileFolder.url.trim()}
                        type="video/mp4"
                      />
                    </video>
                  </AspectRatio>
                </>
              )}
              {viewedFileFolder.extension === "txt" && (
                <Box>
                  <Text>{fileContentFolder}</Text>
                </Box>
              )}
              {viewedFileFolder.extension === "dwg" && (
                <>
                  <PdfViewer url={viewedFileFolder.url.trim()} />
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setViewedFileFolder(null)}
              >
                {content[language].close}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ChakraProvider>
  );
};

export default TableUser;
