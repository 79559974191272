import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Box,
  useColorModeValue,
  Container,
  FormControl,
  Input,
  Button,
  Heading,
  Center,
  InputRightElement,
  InputGroup,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { BiLockAlt } from "react-icons/bi";
import { useLanguage } from "../componets/LanguageContext";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";

const content = {
  en: {
    title: "Sign in to your account",
    email: "Email Address",
    password: "Password",
    signin: "Sign in",
    forgot: "Forgot your password?",
    emailValid: 'Please enter a valid email "example@example.com"',
    emailValidMandatory: "The email field is required.",
    passwordValidMandatory: "The password field is required.",
    loginError: "The email or password is incorrect.",
    loginErrorServe: "Failed to connect to the server.",
    userDisabled: "This user is not active. Please contact the administrator.",
    cancel: "Cancel",
    send: "Send",
    example: "example@example.com",
    sendEmail: "Password recovery email sent",
    sendEmailError: "Error sending the password recovery email.",
    success: {
      sendEmail: "Password recovery email sent.",
    },
    error: {
      sendEmail: "Failed to send the password recovery email.",
    },
  },

  es: {
    title: "Inicia sesión en tu cuenta",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    signin: "Iniciar sesión",
    forgot: "¿Olvidaste tu contraseña?",
    emailValid:
      'Por favor, ingresa un correo electrónico válido "ejemplo@ejemplo.com".',
    emailValidMandatory: "El campo de correo electrónico es obligatorio.",
    passwordValidMandatory: "El campo de contraseña es obligatorio.",
    loginError: "El correo electrónico o contraseña son incorrectos.",
    loginErrorServe: "Fallo al conectar con el servidor.",
    userDisabled:
      "Este usuario no está activo. Por favor, contacte al administrador.",
    cancel: "Cancelar",
    send: "Enviar",
    example: "ejemplo@ejemplo.com",
    sendEmail: "Correo electrónico de recuperación de contraseña enviado",
    success: {
      sendEmail: "Correo electrónico de recuperación de contraseña enviado.",
    },
    error: {
      sendEmail:
        "Fallo al enviar el correo electrónico de recuperación de contraseña.",
    },
  },
};

const Index = () => {
  const API_ENDPOINT_USER = "https://api-siesa.in/api/v1/users";
  const { language } = useLanguage();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError("");
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setPasswordError("");
  };

  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(content[language].emailValid);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;

    if (email.trim() === "") {
      setEmailError(content[language].emailValidMandatory);
      hasError = true;
    }

    if (password.trim() === "") {
      setPasswordError(content[language].passwordValidMandatory);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    axios
      .post(`${API_ENDPOINT_USER}/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.status === 200) {
          const { user, passwordMatch } = res.data;
          if (user) {
            localStorage.setItem("token", user.authtoken);
            if (passwordMatch && user.RoleId === 1 && user.StatusId === 1) {
              localStorage.setItem("user", "admin");
              navigate(`/admin/home?token=${user.authtoken}`);
            } else if (!passwordMatch) {
              setLoginError(content[language].loginError);
            } else if (user.StatusId === 2) {
              setLoginError(content[language].userDisabled);
            } else {
              localStorage.setItem("user", "client");
              navigate(`/client/home?token=${user.authtoken}`);
            }
          } else {
            setLoginError(content[language].loginError);
          }
        } else {
          setLoginError(content[language].loginErrorServe);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoginError(content[language].loginError);
      });
  };

  return (
    <Box pb={8}>
      <Stack
        pos="adbsolute"
        bgGradient={`linear(to-l, blue.500, cyan.400, blue.400)`}
        height="200px"
        width="100%"
      />
      <Box
        maxW="3xl"
        p={4}
        isolation="isolate"
        zIndex={3}
        mt="-10rem"
        marginInline="auto"
      >
        <Box
          boxShadow={useColorModeValue(
            "0 6px 8px rgba(160, 174, 192, 0.6)",
            "0 6px 8px rgba(9, 17, 28, 0.9)"
          )}
          bg={useColorModeValue("white", "gray.800")}
          p={{ base: 4, sm: 8 }}
          overflow="hidden"
          rounded="2xl"
          border="2px solid black"
        >
          <form onSubmit={handleSubmit}>
            <Stack
              pos="relative"
              zIndex={1}
              direction="column"
              spacing={5}
              textAlign="left"
            >
              <Container maxW="7xl" p={{ base: 5, md: 10 }}>
                <Center>
                  <Stack spacing={4}>
                    <Stack align="center">
                      <Heading fontSize="3xl">
                        {content[language].title}
                      </Heading>
                    </Stack>

                    <FormControl id="email">
                      <Input
                        type="email"
                        placeholder={content[language].email}
                        rounded="2xl"
                        border="1px solid black"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                      />
                      {emailError && (
                        <Alert status="error" mt={2} mb={2}>
                          <AlertIcon />
                          {emailError}
                        </Alert>
                      )}
                    </FormControl>
                    <FormControl id="password" position="relative" bottom="1px">
                      <InputGroup>
                        <Input
                          placeholder={content[language].password}
                          rounded="2xl"
                          type={showPassword ? "text" : "password"}
                          border="1px solid black"
                          onChange={handlePasswordChange}
                        />
                        <InputRightElement>
                          {showPassword ? (
                            <ViewIcon
                              h={5}
                              w={5}
                              onClick={handleTogglePassword}
                              cursor="pointer"
                            />
                          ) : (
                            <ViewOffIcon
                              h={5}
                              w={5}
                              onClick={handleTogglePassword}
                              cursor="pointer"
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                      {passwordError && (
                        <Alert status="error" mt={2}>
                          <AlertIcon />
                          {passwordError}
                        </Alert>
                      )}
                    </FormControl>

                    <Button
                      type="submit"
                      border="1px solid black"
                      leftIcon={<BiLockAlt />}
                      bg="#f8b603"
                      color="white"
                      _hover={{
                        bg: "yellow.400",
                      }}
                      rounded="md"
                      w="100%"
                    >
                      {content[language].signin}
                    </Button>
                    {loginError && (
                      <Alert status="error" mt={4}>
                        <AlertIcon />
                        {loginError}
                      </Alert>
                    )}
                  </Stack>
                </Center>
              </Container>
            </Stack>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
