import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  ChakraProvider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const content = {
  en: {
    titleFile: "Edit File",
    name: "File name:",
    description: "File description:",
    uploadFiles: "Upload file:",
    cancel: "Cancel",
    save: "Save",
    selectDiscipline: "Discipline of file:",
    selectDisciplines: "Select a discipline",
    titleEdit: "Edit file",
    errors: {
      validation: "Please select values ​​for all options.",
      edit: "Error editing the file. Please try again.",
      nameRequired: "Please provide a name for the file.",
      descriptionRequired: "Please provide a description for the file.",
      disciplineRequired: "Please select a discipline for the file.",
      fileRequired: "Please upload at least one file.",
    },
    success: {
      edit: "The file has been edited successfully.",
    },
    remove: "Remove",
  },
  es: {
    titleFile: "Editar Archivo",
    name: "Nombre del archivo:",
    description: "Descripción del archivo:",
    uploadFiles: "Subir archivo:",
    cancel: "Cancelar",
    save: "Guardar",
    selectDiscipline: "Disciplina del archivo:",
    selectDisciplines: "Seleccionar una disciplina",
    titleEdit: "Archivo editado",
    errors: {
      validation: "Por favor, seleccione valores para todas las opciones.",
      edit: "Error al editar el archivo. Por favor, inténtelo de nuevo.",
      nameRequired: "Por favor, proporcione un nombre para el archivo.",
      descriptionRequired:
        "Por favor, proporcione una descripción para el archivo.",
      disciplineRequired:
        "Por favor, seleccione una disciplina para el archivo.",
      fileRequired: "Por favor, cargue al menos un archivo.",
    },
    success: {
      edit: "El archivo se ha editado correctamente.",
    },
    remove: "Eliminar",
  },
};

const ModalEditFile = ({ isOpen, onClose, fileId, fetchData }) => {
  const API_ENDPOINT_FILE = "https://api-siesa.in/api/v1/files";
  const API_ENDPOINT_DISCIPLINE = "https://api-siesa.in/api/v1/disciplines";

  const toast = useToast();
  const { language } = useLanguage();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    disciplineId: "",
    file: null,
  });

  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        if (fileId) {
          const response = await axios.get(
            `${API_ENDPOINT_FILE}/file-details/${fileId}`
          );

          setFormData({
            name: response.data.name || "",
            description: response.data.description || "",
            disciplineId: response.data.Discipline?.id || "",
            file: null,
          });
        }
      } catch (error) {
        console.error("Error al obtener detalles:", error);
      }
    };

    fetchFileDetails();
  }, [isOpen, fileId]);

  useEffect(() => {
    const fetchDisciplineDetails = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT_DISCIPLINE}/getAllDisciplines`
        );
        setDisciplineOptions(response.data);
      } catch (error) {
        console.error("Error al obtener los detalles de la disciplina:", error);
      }
    };

    fetchDisciplineDetails();
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const selectedFile = files.length > 0 ? files[0] : null;
      setFormData((prevData) => ({
        ...prevData,
        file: selectedFile,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const editFile = async () => {
    try {
      if (!formData.name) {
        toast({
          title: "Error",
          description: content[language].errors.nameRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.description) {
        toast({
          title: "Error",
          description: content[language].errors.descriptionRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.disciplineId) {
        toast({
          title: "Error",
          description: content[language].errors.disciplineRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!formData.file) {
        toast({
          title: "Error",
          description: content[language].errors.fileRequired,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);

      // Create FormData
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("DisciplineId", formData.disciplineId);

      if (formData.file) {
        formDataToSend.append("file", formData.file);
      }

      const response = await axios.put(
        `${API_ENDPOINT_FILE}/updateFiles/${fileId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: content[language].titleEdit,
          description: content[language].success.edit,
          status: "success",
          duration: 10000,
          isClosable: true,
        });

        setFormData({
          name: "",
          description: "",
          disciplineId: "",
          file: null,
        });

        onClose && onClose();
      } else {
        toast({
          title: "Error",
          description: content[language].errors.edit,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: content[language].errors.edit,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setFormData({
      name: "",
      description: "",
      disciplineId: "",
      files: [],
    });

    onClose && onClose();
    fetchData();
  };

  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {content[language].titleFile}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              borderWidth="1px"
              rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              maxWidth={800}
              p={6}
              m="10px auto"
              as="form"
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">{content[language].name}</FormLabel>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="description">
                  {content[language].description}
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="disciplineId">
                  {content[language].selectDiscipline}
                </FormLabel>
                <Select
                  id="disciplineId"
                  name="disciplineId"
                  placeholder={content[language].selectDisciplines}
                  value={formData.disciplineId}
                  onChange={handleChange}
                >
                  {disciplineOptions.map((discipline) => (
                    <option key={discipline.id} value={discipline.id}>
                      {discipline.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel htmlFor="file">
                  {content[language].uploadFiles}
                </FormLabel>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%">
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => handleClose()}
                  colorScheme="gray"
                  variant="outline"
                  w="5rem"
                  mr="2%"
                >
                  {content[language].cancel}
                </Button>
                <Button
                  onClick={editFile}
                  colorScheme="blue"
                  variant="solid"
                  isLoading={loading}
                >
                  {content[language].save}
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalEditFile;
